// Core
import { useDispatch } from 'react-redux'
import { useState, useEffect } from 'react'
// Styles
import Styles from './Styles.module.scss'
// MUI
import { Modal } from '@mui/material'
// Icons
import { ReactComponent as CloseIcon } from '../../../../theme/assets/icons/close_updated.svg'
// Actions
import { profileActions } from '../../../../actions'
// Utils
import dayjs, { utc } from 'dayjs'
// i18n
import { useTranslation } from 'react-i18next'
import i18n from '../../../../i18n'
import ar from './locale/ar.json'
import en from './locale/en.json'

dayjs.extend(utc)

export const AcceptRecordingModal = ({ onClose, open, openCalCom, setMetadata }) => {
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const [ipAddress, setIpAddress] = useState(null)
  const [userOption, setUserOption] = useState(true)

  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language'))

  useEffect(() => {
    setSelectedLanguage(localStorage.getItem('language'))
    i18n.changeLanguage(localStorage.getItem('language'))
  }, [localStorage.getItem('language')])

  useEffect(() => {
    i18n.addResourceBundle('en', 'recording_modal', en)
    i18n.addResourceBundle('ar', 'recording_modal', ar)
  }, [])
  const handleSelectOption = (option) => {
    setUserOption(option)
    const dataForRequest = {
      setData: (ipAddress) => setIpAddress(ipAddress),
    }
    dispatch(profileActions.getIPAddress(dataForRequest))
  }

  useEffect(() => {
    if (ipAddress) {
      const metadata = {
        consent: userOption,
        user_agent: navigator.userAgent,
        ip: ipAddress,
        time: dayjs.utc().toISOString(),
      }

      setMetadata(metadata)
      onClose()
      openCalCom(true)
    }
  }, [ipAddress, userOption])

  return (
    <Modal onClose={onClose} open={open}>
      <div className={Styles.main_container}>
        <div style={{ flexDirection: selectedLanguage === 'ar' && 'row-reverse' }} className={Styles.main_container__header}>
          <p>{t('recording_modal:header')}</p>
          <CloseIcon onClick={onClose} />
        </div>
        <div className={Styles.main_container__content}>
          <p style={{ direction: selectedLanguage === 'ar' && 'rtl' }}>{t('recording_modal:main_text')}</p>
          <span style={{ direction: selectedLanguage === 'ar' && 'rtl' }}>{t('recording_modal:sub_text')}</span>
          <div className={`${Styles.main_container__content__btns} ${selectedLanguage === 'ar' && Styles.btns_ar}`}>
            <div onClick={() => handleSelectOption(false)}>
              <p>{t('recording_modal:btns:decline')}</p>
            </div>
            <div onClick={() => handleSelectOption(true)}>
              <span>{t('recording_modal:btns:accept')}</span>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
