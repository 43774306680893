export const arabicValidationErrors = {
  required: 'هذا الحقل النموذج مطلوب',
  lengthShouldBeBetween3To30: 'يجب أن يكون اسمك من 3 إلى 30 حرفًا على الأقل',
  nameShouldNotContainNumber: 'يجب ألا يحتوي اسمك على أرقام',
  invalidDate: 'تاريخ الميلاد غير صحيح',
  mustBeOneYearAgo: 'يجب أن يكون التاريخ منذ عام واحد على الأقل',
  mustBeYounger: 'يجب أن يكون الطفل أصغر سنًا',
  atLeastThreeCharLong: 'يجب أن يكون هذا الحقل 3 أحرف على الأقل',
  noMoreThan30CharLong: 'يجب ألا يزيد طول هذا الحقل عن 30 حرفًا',
  noMoreThan510CharLong: 'يجب ألا يزيد طول هذا الحقل عن 510 حرفًا',
  noMOreThan5CharLong: 'يجب ألا يزيد طول هذا الحقل عن 5 أحرف',
  shouldHaveAtLeastOneOption: 'يجب أن يحتوي هذا الحقل على خيار واحد على الأقل',
  invalidEmail: 'الرجاء إدخال عنوان بريد إلكتروني صحيح',
  passwordValidation: 'يجب أن تتكون كلمة سر الخاصة بك من ثمانية أحرف على الأقل، وأن تحتوي على حرف واحد على الأقل، ورقم واحد وحرف خاص',
  agreeToTermsAndConditions: 'يجب عليك الموافقة على الشروط والأحكام',
  agreeToPrivacyPolicy: 'يجب عليك الموافقة على سياسة الخصوصية',
}
