// Core
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
// Actions
import { advisorOnbordingActions } from '../../actions/advisorOnbordingActions'
//Components
import { GeneralProfileInformation, ParentProfileQuestionnairePart, ProfileTherapistsBlock } from '../../components'
// Styles
import Styles from './Styles.module.scss'

export const AdvisorAssignTherapistsPage = () => {
  const dispatch = useDispatch()

  const user = useSelector((state) => state.auth.user)
  const currentProfile = useSelector((state) => state.advisorOnbording.currentParent)
  const allTherapists = useSelector((state) => state.advisorOnbording.allTherapists)

  const { profileId } = useParams()

  const [profileInfo, setProfileInfo] = useState(null)
  const [clientInfo, setClientInfo] = useState(null)
  const [questionnaire, setQuestionnaire] = useState(null)
  const [assignedTherapists, setAssignedTherapists] = useState(null)

  const [refetch, setRefetch] = useState(true)

  useEffect(() => {
    if (profileId && user?.token) {
      const dataForRequest = {
        id: profileId,
        token: user?.token,
      }

      dispatch(advisorOnbordingActions.getParentInfoForAssigning(dataForRequest))
      dispatch(advisorOnbordingActions.getAllTherapists({ token: user.token }))
    }
  }, [profileId, user, refetch])

  useEffect(() => {
    if (currentProfile) {
      const profileInfo = {
        full_name: currentProfile?.profile?.full_name,
        age: currentProfile?.profile?.age,
        birth_date: currentProfile?.profile?.birth_date,
        languages: currentProfile?.profile?.languages,
        concerns: currentProfile?.concerns,
        behavior: currentProfile?.questionnaire?.child_info?.behavior,
        assignedTherapists: currentProfile?.therapists,
      }

      const clientInfo = {
        full_name: currentProfile?.parent?.full_name,
        country: currentProfile?.parent?.country,
      }

      const finalQuestionnaire = []

      if (currentProfile?.profile?.questionnaire?.questionnaire && currentProfile?.questions) {
        for (const category of currentProfile?.profile?.questionnaire?.questionnaire) {
          for (const onboardedCategory of currentProfile?.questions) {
            if (category?.id === onboardedCategory?.id) {
              finalQuestionnaire.push({ ...category, advisor_comment: onboardedCategory?.advisor_comment || '' })
            }
          }
        }
      }

      const questionnaire = {
        familyDynamics: currentProfile?.profile?.questionnaire?.family_dynamics,
        parentalWellbeing: currentProfile?.profile?.questionnaire?.parental_wellbeing,
        questionnaire: finalQuestionnaire,
      }

      setProfileInfo(profileInfo)
      setClientInfo(clientInfo)
      setQuestionnaire(questionnaire)
      setAssignedTherapists(currentProfile?.therapists)
    }
  }, [currentProfile])

  return (
    <div className={Styles.main_container}>
      <div className={Styles.general_block}>
        <div className={Styles.general_block__info}>
          <GeneralProfileInformation profile={profileInfo} client={clientInfo} />
        </div>
        <div className={Styles.questionnaire_block}>
          <ParentProfileQuestionnairePart questionnaire={questionnaire} />
        </div>
      </div>
      <div className={Styles.therapists_block}>
        <ProfileTherapistsBlock allTherapists={allTherapists} assignedTherapists={assignedTherapists} setRefetch={setRefetch} />
      </div>
    </div>
  )
}
