// eslint-disable-next-line no-undef
export const apiPath = process.env.REACT_APP_API_PATH
export const paymnetPath = process.env.REACT_APP_PAYMENT_PATH
export const meetingSdkKey = process.env.REACT_APP_MEETING_SDK_KEY
export const paymentKey = process.env.REACT_APP_PAYMENT_KEY
export const threeDSecure = process.env.REACT_APP_PAYMENT_3D_SECURE
export const PRIVACY_POLICY_LINK = process.env.REACT_APP_PRIVACY_POLICY_LINK
export const TERMS_OF_USE_LINK = process.env.REACT_APP_TERMS_OF_USE_LINK
export const CALCOM_ADVISOR_CHILD_LINK = process.env.REACT_APP_CAL_COM_LINK_CHILD

export const availableChatTypesForUserRole = {
  parent: [
    { value: 'therapist', name: 'therapist' },
    { value: 'advisor', name: 'advisor' },
  ],
  therapist: [
    { value: 'parent', name: 'client' },
    { value: 'advisor', name: 'advisor' },
  ],
  advisor: [
    { value: 'therapist', name: 'therapist' },
    { value: 'parent', name: 'client' },
  ],
  admin: [{ value: 'advisor', name: 'advisor' }],
}

//Regex
export const EMAIL_REGEX = /^[_a-z0-9-\+-]+(\.[_a-z0-9-\+-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/i
export const PASSWORD_REGEX = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*]{8,}$/i

export const userTypes = {
  parent: 'parent',
  therapist: 'therapist',
  advisor: 'advisor',
  admin: 'admin',
}
