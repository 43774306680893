//Core
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useState, useRef, useEffect } from 'react'
import React from 'react'
//Styles
import Styles from './styles.module.scss'
//Icons
import { ReactComponent as BackIcon } from '../../../../theme/assets/icons/arrow_left_icon.svg'
//Components
import { Note } from './Note'
import { NoDataComponent } from '../../../GeneralComponents/NoDataComponent'
import { CreateNotePopup } from '../../../ParentFlow/Therapy/CreateNotePopup'
//Actions
import { therapistActions } from '../../../../actions/therapistActions'

export const Notes = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const user = useSelector((state) => state.auth.user)
  const notes = useSelector((state) => state.parent.childNotes)

  const { profileId } = useParams()

  const [isOpenCreateNotePopup, setIsOpenCreateNotePopup] = useState(false)
  const [refetch, setRefetch] = useState(false)

  useEffect(() => {
    const data = {
      token: user?.token,
      id: 'childId',
    }
    dispatch(therapistActions.getNotes(data))
  }, ['childId', refetch])

  const handleGoBack = () => {
    navigate(-1)
  }

  const handleOpenCreateNotePopup = () => {
    setIsOpenCreateNotePopup(true)
  }

  const handleCloseCreateNotePopup = () => {
    setIsOpenCreateNotePopup(false)
    setRefetch((prev) => !prev)
  }

  return (
    <div className={Styles.main_container}>
      <div onClick={handleGoBack} className={Styles.header}>
        <BackIcon />
        <h6>Back to the profile</h6>
      </div>
      <div className={Styles.content}>
        {notes.length ? notes?.map((note) => <Note userType={user?.user?.user_type} note={note} />) : <NoDataComponent text={`Your therapy notes will appear here.`} />}
      </div>
      {user?.user?.user_type === 'therapist' && (
        <div className={Styles.btn}>
          <div onClick={handleOpenCreateNotePopup}>New note</div>
        </div>
      )}
      {isOpenCreateNotePopup && <CreateNotePopup open={isOpenCreateNotePopup} onClose={handleCloseCreateNotePopup} childId={'childId'} />}
    </div>
  )
}
