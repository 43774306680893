//Core
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
//Actions
import { advisorOnbordingActions } from '../../../actions'
//Utils
import { getInitials, returnCountry } from '../../../utils'
//Styles
import Styles from './styles.module.scss'
//Mui
import { InputBase } from '@mui/material'
//Icons
import { ReactComponent as SearchIcon } from '../../../theme/assets/icons/search_convex.svg'
import { ReactComponent as ArrowUpIcon } from '../../../theme/assets/icons/arrow_up_icon.svg'
import { ReactComponent as ArrowDownIcon } from '../../../theme/assets/icons/arrow_down_icon.svg'
import { ReactComponent as TickIcon } from '../../../theme/assets/icons/check_updated.svg'
//Utils
import { sortArray } from '../../../utils'
//Components
import { TherapistInfoModal } from '../../AdvisorFlow/Therapists/TherapistsInfoModal'

/**
 * AssignTherapists component for assigning therapists to a parent profile.
 *
 * @component
 * @returns {JSX.Element} The AssignTherapists component.
 */
export const ProfileTherapistsBlock = ({ allTherapists, assignedTherapists, setRefetch }) => {
  const dispatch = useDispatch()

  const { profileId } = useParams()

  const user = useSelector((state) => state.auth.user)

  /**
   * State to store the list of therapists.
   * @type {Array}
   */
  const [therapists, setTherapists] = useState([])

  /**
   * State to store the list of therapists to be assigned.
   * @type {Array}
   */
  const [therapistsForAssign, setTherapistsForAssign] = useState([])

  /**
   * State to store the list of therapists assigned earlier.
   * @type {Array}
   */
  const [therapistsAssignedEarlier, setTherapistsAssignedEarlier] = useState([])

  /**
   * State to store the selected therapist ID.
   * @type {string|undefined}
   */
  const [selectedTherapistId, setSelectedTherapistId] = useState([])

  /**
   * State to manage sorting order for qualification.
   * @type {boolean}
   */
  const [isQualificationDescending, setIsQualificationDescending] = useState(true)

  /**
   * State to manage sorting order for language.
   * @type {boolean}
   */
  const [isLanguageDescending, setIsLanguageDescending] = useState(true)

  /**
   * State to manage sorting order for time zone.
   * @type {boolean}
   */
  const [isTimeDescending, setIsTimeDescending] = useState(true)

  /**
   * State to manage the visibility of the menu.
   * @type {boolean}
   */
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  /**
   * State to manage the visibility of the therapist details modal.
   * @type {boolean}
   */
  const [isTherapistDetailsOpen, setIsTherapistDetailsOpen] = useState(false)

  /**
   * Update therapists state when allTherapists changes.
   */
  useEffect(() => {
    setTherapists([...allTherapists])
  }, [allTherapists])

  /**
   * Update onboarding questions and previously assigned therapists when the application or type changes.
   */
  useEffect(() => {
    const assignedTherapistsEarlier = assignedTherapists?.map((therapist) => therapist.id)
    if (assignedTherapistsEarlier) {
      setTherapistsAssignedEarlier([...assignedTherapistsEarlier])
    }
  }, [assignedTherapists])

  /**
   * Handles input change for searching therapists.
   *
   * @param {object} e - Event object.
   */
  const handleChangeInput = (e) => {
    const inputValue = e.target.value.toLowerCase()

    const filteredArray = allTherapists.filter((therapist) => therapist.user.full_name.toLowerCase().includes(inputValue))
    setTherapists(filteredArray)
  }

  /**
   * Handles sorting of therapists based on the given type and sort field.
   *
   * @param {boolean} type - Sorting type (ascending/descending).
   * @param {string} sortField - Field to sort by.
   */
  const handleSortQualification = (type, sortField) => {
    switch (sortField) {
      case 'ADVISOR_THERAPIST_QUALIFICATION':
        setIsQualificationDescending((prev) => !prev)
        break
      case 'ADVISOR_THERAPIST_LANGUAGE':
        setIsLanguageDescending((prev) => !prev)
        break
      case 'ADVISOR_THERAPIST_TIMEZONE':
        setIsTimeDescending((prev) => !prev)
        break
    }

    sortArray(type, therapists, sortField)
  }

  /**
   * Checks if a therapist is selected.
   *
   * @param {string} therapistId - ID of the therapist.
   * @returns {boolean} - Whether the therapist is selected or not.
   */
  const checkIsTherapistSelected = (therapistId) => {
    const isSelected = therapistsForAssign.find((id) => id === therapistId) || therapistsAssignedEarlier.find((id) => id === therapistId)
    return isSelected
  }
  const checkAssignedAlready = (therapistId) => {
    const alreadyAssigned = therapistsAssignedEarlier.find((id) => id === therapistId)
    return alreadyAssigned
  }

  /**
   * Handles the opening of the therapist menu.
   *
   * @param {string} id - ID of the therapist.
   */
  const handleOpenTherapistMenu = (id) => {
    setSelectedTherapistId(id)
    setIsMenuOpen(true)
  }

  /**
   * Handles the closing of the therapist menu.
   */
  const handleCloseTherapistMenu = () => {
    setIsMenuOpen(false)
  }

  /**
   * Opens the therapist info modal.
   */
  const handleOpenTherapistInfo = () => {
    setIsTherapistDetailsOpen(true)
  }

  /**
   * Closes the therapist info modal.
   */
  const handleCloseTherapistInfo = () => setIsTherapistDetailsOpen(false)

  /**
   * Handles the selection of therapists for assignment.
   *
   * @param {string} therapistId - ID of the therapist.
   */
  const handleSelectTherapistsForAssign = (therapistId) => {
    const isSelected = !!checkIsTherapistSelected(therapistId)
    if (isSelected) {
      const newArrayOfTherapists = therapistsForAssign.filter((id) => id !== therapistId)
      setTherapistsForAssign([...newArrayOfTherapists])
    } else {
      setTherapistsForAssign((prev) => [...prev, therapistId])
    }
    setIsMenuOpen(false)
  }

  /**
   * Resets the selection of all therapists.
   */
  const handleResetAllTherapists = () => setTherapistsForAssign([])

  /**
   * Handles the assignment of selected therapists.
   */
  const handleAssignTherapists = () => {
    if (!therapistsForAssign.length) return null

    const data = {
      id: profileId,
      token: user?.token,
      payload: {
        therapists: therapistsForAssign,
      },
    }
    dispatch(advisorOnbordingActions.assignTherapists(data)).then(() => {
      setRefetch((prev) => !prev)
      setTherapistsForAssign([])
    })
  }

  return (
    <div className={Styles.main_container}>
      <div className={Styles.second_block}>
        <div className={Styles.second_header}>
          <div className={Styles.second_header__hint}>
            <h6>Therapists</h6>
            <span>You can assign a minimum of 1 therapist per person</span>
          </div>
          <div className={Styles.second_header__sorting}>
            <div className={Styles.second_header__sorting__search}>
              <div className={Styles.second_header__sorting__search__container}>
                <SearchIcon />
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search by name"
                  onChange={(e) => handleChangeInput(e)}
                  className={Styles.second_header__sorting__search__container__text}
                />
              </div>
            </div>
            <div className={Styles.second_header__filters}>
              <div onClick={() => handleSortQualification(isQualificationDescending, 'ADVISOR_THERAPIST_QUALIFICATION')} className={Styles.second_header__filters__item}>
                Type
                {!isQualificationDescending ? <ArrowDownIcon /> : <ArrowUpIcon />}
              </div>
              <div onClick={() => handleSortQualification(isLanguageDescending, 'ADVISOR_THERAPIST_LANGUAGE')} className={Styles.second_header__filters__item}>
                Language
                {!isLanguageDescending ? <ArrowDownIcon /> : <ArrowUpIcon />}
              </div>
              <div onClick={() => handleSortQualification(isTimeDescending, 'ADVISOR_THERAPIST_TIMEZONE')} className={Styles.second_header__filters__item}>
                Timezone
                {!isTimeDescending ? <ArrowDownIcon /> : <ArrowUpIcon />}
              </div>
            </div>
          </div>
        </div>
        <div className={Styles.second_content_block}>
          {therapists?.map((therapist) => {
            return (
              <div key={therapist.id} className={Styles.therapist_block}>
                <div className={Styles.therapist_card}>
                  <div className={Styles.therapist_card__left}>
                    <div className={Styles.therapist_card__initials}>
                      {checkIsTherapistSelected(therapist?.id) ? (
                        <TickIcon />
                      ) : therapist?.user?.profile_pic ? (
                        <img src={therapist?.user?.profile_pic} />
                      ) : (
                        getInitials(therapist?.user?.full_name)
                      )}
                    </div>
                    <div className={Styles.therapist_card__personal_info}>
                      <p>{therapist?.user?.full_name}</p>
                      <span>{therapist?.professional_qualities}</span>
                    </div>
                  </div>
                  {isMenuOpen && selectedTherapistId === therapist?.id ? (
                    <ArrowUpIcon onClick={handleCloseTherapistMenu} className={Styles.cursor} />
                  ) : (
                    <ArrowDownIcon onClick={() => handleOpenTherapistMenu(therapist?.id)} className={Styles.cursor} />
                  )}
                </div>
                {isMenuOpen && selectedTherapistId === therapist?.id && (
                  <div className={Styles.therapist_menu_block}>
                    <div className={Styles.therapist_menu_block__personal_info}>
                      <div className={Styles.therapist_menu_block__personal_info__item}>
                        <span>Years experience</span>
                        <p>{therapist?.experience}</p>
                      </div>
                      <div className={Styles.therapist_menu_block__personal_info__item}>
                        <span>Language</span>
                        <p>{therapist?.languages?.join(', ')}</p>
                      </div>
                      <div className={Styles.therapist_menu_block__personal_info__item}>
                        <span>Country</span>
                        <p>{returnCountry(therapist?.user?.country)}</p>
                      </div>
                      <div className={Styles.therapist_menu_block__personal_info__item}>
                        <span>Timezone</span>
                        <p>{therapist?.user?.experience || '-'}</p>
                      </div>
                    </div>
                    <div className={Styles.therapist_menu_block__footer}>
                      <div onClick={handleOpenTherapistInfo} className={Styles.therapist_menu_block__footer__details_btn}>
                        Details
                      </div>
                      {!checkAssignedAlready(therapist?.id) && (
                        <div onClick={() => handleSelectTherapistsForAssign(therapist?.id)} className={Styles.therapist_menu_block__footer__submit_btn}>
                          {checkIsTherapistSelected(therapist.id) ? 'Reset' : 'Assign'}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )
          })}
        </div>
        <div className={Styles.second_footer}>
          <div onClick={handleResetAllTherapists} className={therapistsForAssign.length ? Styles.second_footer__reset_btn : Styles.second_footer__reset_btn_disabled}>
            Reset all
          </div>
          <div onClick={handleAssignTherapists} className={therapistsForAssign.length ? Styles.second_footer__submit_btn : Styles.second_footer__submit_btn_disabled}>
            Submit
          </div>
        </div>
      </div>
      {isTherapistDetailsOpen && <TherapistInfoModal open={isTherapistDetailsOpen} onClose={handleCloseTherapistInfo} therapistId={selectedTherapistId} />}
    </div>
  )
}
