const therapistRoot = 'therapists'
const userRoot = 'users'
/**
 * Initializing API routes, one centralized place for all API routes
 * Easier to maintain and update
 * */
export const apiRoutes = {
  therapist: {
    create: `${therapistRoot}/create/`,
    get: `${therapistRoot}/`,
  },
  user: {
    checkEmailForAuthenticated: `${userRoot}/check-mail-authenticated/`,
  },
}
