// Instruments
import axios from 'axios'
// Constants
import { apiPath } from '../constants'

export const profileApi = {
  updateHintModalStep: (data) => {
    return axios({
      method: 'PUT',
      url: `${apiPath}/parents/profiles/${data.id}/onboarding/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
      data: JSON.stringify(data.payload),
    })
  },
  getIPAddress: () => {
    return axios({
      method: 'GET',
      url: 'https://api.ipify.org/?format=json',
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
}
