// Instruments
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import * as Yup from 'yup'

/**
 *
 * @param {import('i18next').TFunction} t - The translation function to use for error messages.
 * @returns {object} - A Yup schema that can be used to validate the form fields.
 */
export const schemaValid = (t) => {
  const maxDate = new Date()
  const minDate = new Date()

  maxDate.setFullYear(maxDate.getFullYear() - 18)
  minDate.setFullYear(minDate.getFullYear() - 1)

  return yupResolver(
    Yup.object().shape({
      child_name: Yup.string()
        .required(t('validation:required'))
        .max(30, t('validation:noMoreThan30CharLong'))
        .min(3, t('validation:atLeastThreeCharLong'))
        .matches(/^[a-zA-Z\u0600-\u06FF\s.'-]*$/i, t('validation:nameShouldNotContainNumber')),
      birth_date: Yup.date()
        .typeError(t('validation:invalidDate'))
        .test('not-in-future', t('validation:invalidDate'), (value) => !value || value <= new Date())
        .max(minDate, t('validation:mustBeOneYearAgo'))
        .min(maxDate, t('validation:mustBeYounger'))
        .nullable(true)
        .required(t('validation:required')),
      concerns: Yup.array()
        .of(
          Yup.lazy((value) => {
            switch (typeof value) {
              case 'object':
                return Yup.object().shape({
                  value: Yup.string().min(3, t('validation:atLeastThreeCharLong')),
                })
              case 'string':
                return Yup.string().min(3, t('validation:atLeastThreeCharLong'))
              default:
                return Yup.mixed()
            }
          }),
        )
        .nullable(true)
        .required(t('validation:required'))
        .min(1, t('validation:shouldHaveAtLeastOneOption')),
      languages: Yup.array()
        .of(
          Yup.lazy((value) => {
            switch (typeof value) {
              case 'object':
                return Yup.object().shape({
                  value: Yup.string().min(3, t('validation:atLeastThreeCharLong')),
                })
              case 'string':
                return Yup.string().min(3, t('validation:atLeastThreeCharLong'))
              default:
                return Yup.mixed()
            }
          }),
        )
        .nullable(true)
        .required(t('validation:required'))
        .min(1, t('validation:shouldHaveAtLeastOneOption')),
    }),
  )
}
