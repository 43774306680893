import { height, variant } from 'styled-system'
import { commonComposes } from '../common'
import type { CommonProps } from '../common/types'
import styled from 'styled-components'

export interface BoxProps extends CommonProps {
  variant?: 'modal-container'
}
export const Box = styled('div')<BoxProps>(
  (props) => ({
    '&:hover': props._hover,
    transition: 'all 0.1s ease-in-out',
  }),
  variant({
    variants: {
      'modal-container': {
        background: 'white',
        // padding: '24px',
        height: 'auto',
        position: 'absolute',
        borderRadius: '16px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        flexDirection: 'column',
        outline: 'none',
        padding: ['10px', '10px', '10px', '24px'],
        width: ['95%', '95%', '95%', '90%', '809px'],
      },
    },
  }),
  commonComposes(),
)

export const Flex = styled(Box)({
  display: 'flex',
})

export const CenterRow = styled(Flex)({
  alignItems: 'center',
})

export const Container = styled(Flex)`
  justify-content: ${(props) => props.justifyContent || 'center'};
  align-items: ${(props) => props.alignItems || 'center'};
`
export const ModalContentContainer = styled(Box)({
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export const ModalContent = styled(Box)({
  maxHeight: '90%',
  width: '50%',
  display: 'flex',
  justifyContent: 'center',
  background: 'white',
})
