// Instruments
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
//REGEX
import { EMAIL_REGEX, PASSWORD_REGEX } from '../../../../constants'

/**
 * Generates a Yup schema validator for the parent onboarding registration form.
 *
 * @param {function} t - A translation function for localized error messages.
 * @returns {object} - A Yup schema validator object.
 */
export const schemaValid = (t) => {
  return yupResolver(
    Yup.object().shape({
      email: Yup.string().required(t('validation:required')).matches(EMAIL_REGEX, t('validation:invalidEmail')),
      first_name: Yup.string()
        .required(t('validation:required'))
        .min(3, t('validation:atLeastThreeCharLong'))
        .max(30, t('validation:noMoreThan30CharLong'))
        .matches(/^[a-zA-Z\u0600-\u06FF\s.'-]*$/i, t('validation:nameShouldNotContainNumber')),
      last_name: Yup.string()
        .required(t('validation:required'))
        .min(3, t('validation:atLeastThreeCharLong'))
        .max(30, t('validation:noMoreThan30CharLong'))
        .matches(/^[a-zA-Z\u0600-\u06FF\s.'-]*$/i, t('validation:nameShouldNotContainNumber')),
      password: Yup.string().required(t('validation:required')).matches(PASSWORD_REGEX, t('validation:passwordValidation')),
      password_2: Yup.string().required(t('validation:required')).matches(PASSWORD_REGEX, t('validation:passwordValidation')),
      is_agree_terms_conditions: Yup.boolean().oneOf([true], t('validation:agreeToTermsAndConditions')),
      is_agree_privacy_policy: Yup.boolean().oneOf([true], t('validation:agreeToPrivacyPolicy')),
    }),
  )
}
