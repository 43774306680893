// Styles
import Styles from './Styles.module.scss'
// Utils
import { getInitials, returnCountry } from '../../../utils'
import { useDisclosure } from '../../../hooks/useDisclosure'
import { CloseIcon, EyeVisible } from '../../../theme/assets/icons'
import { Box, IconButton, Modal } from '@mui/material'
import { useState } from 'react'

export const GeneralProfileInformation = ({ profile, client, advisor }) => {
  const [reason, setReason] = useState('')
  const { open, onClose, onOpen } = useDisclosure()
  return (
    <div className={Styles.main_container}>
      <div className={Styles.main_container__header}>
        <div className={Styles.main_container__header__initials}>{getInitials(profile?.full_name)}</div>
        <h6>{profile?.full_name}</h6>
      </div>
      <div className={Styles.main_container__profile_info}>
        <div className={Styles.main_container__profile_info__container}>
          <div>
            <p>Age:</p>
            <span>{profile?.age}</span>
          </div>
          <div>
            <p>Birthday:</p>
            <span>{profile?.birth_date}</span>
          </div>
        </div>
        <div className={Styles.main_container__profile_info__container}>
          <div>
            <p>Languages:</p>
            <span>{profile?.languages?.join(', ')}</span>
          </div>
          <div>
            <p>Problems:</p>
            <span>{profile?.concerns?.join(', ')}</span>
          </div>
        </div>
        <div className={Styles.main_container__profile_info__container}>
          <div style={{ width: '100%' }}>
            <p>Usual behavior:</p>
            <span>{profile?.behavior?.join(', ')}</span>
          </div>
        </div>
      </div>
      <div className={Styles.main_container__client_info}>
        <div className={Styles.main_container__client_info__container}>
          <div>
            <p>Client name:</p>
            <span>{client?.full_name}</span>
          </div>
          <div>
            <p>Country:</p>
            {returnCountry(client?.country)}
          </div>
        </div>
      </div>
      <div>
        <h6>Assigned Therapists Status</h6>
        <div style={{ gap: '5px', marginTop: 10 }} className={Styles.main_container__client_info}>
          {profile?.assignedTherapists?.map((therapist) => (
            <div className={Styles.main_container__client_info__container} key={therapist?.id}>
              <div>
                <span>{therapist?.user?.full_name}</span>
              </div>
              <div>
                <p>Therapist:</p>
                <span style={{ textTransform: 'uppercase', color: statusColor[therapist?.approval_status?.therapist_status] }}>{therapist?.approval_status?.therapist_status}</span>
                <ShowReason
                  onClick={() => {
                    setReason(therapist?.approval_status?.decline_reason)
                    onOpen()
                  }}
                  onOpen={onOpen}
                  status={therapist?.approval_status?.therapist_status}
                />
              </div>
              <div>
                <p>Parent:</p>
                <span style={{ textTransform: 'uppercase', color: statusColor[therapist?.approval_status?.parent_status] }}>{therapist?.approval_status?.parent_status}</span>
                <ShowReason
                  onClick={() => {
                    setReason(therapist?.approval_status?.decline_reason)
                    onOpen()
                  }}
                  onOpen={onOpen}
                  status={therapist?.approval_status?.parent_status}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      {advisor && (
        <div className={Styles.main_container__advisor_info}>
          <div className={Styles.main_container__advisor_info__initials}>{getInitials(advisor?.full_name)}</div>
          <div>
            <p>{advisor?.full_name}</p>
            <span>Advisor</span>
          </div>
        </div>
      )}
      <PopupDetail onClose={onClose} open={open} reason={reason} />
    </div>
  )
}

const statusColor = {
  waiting: '#FFC107',
  accepted: '#4CAF50',
  declined: '#F44336',
}

const PopupDetail = ({ reason, open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box
        bgcolor="white"
        p={3}
        sx={{
          width: ['90%', '70%', '50%', '30%'],
        }}
        borderRadius={3}
      >
        <Box display="flex" mb={1} justifyContent="space-between" alignItems="center">
          <h6>Reason</h6>
          <IconButton>
            <CloseIcon className={Styles.header__icon} onClick={onClose} />
          </IconButton>
        </Box>
        {reason}
      </Box>
    </Modal>
  )
}

const ShowReason = ({ status, onClick }) => {
  return status === 'declined' ? (
    <IconButton onClick={onClick}>
      <EyeVisible />
    </IconButton>
  ) : null
}
