import { useCreateTherapistAccountMutation } from '../service'

export const useSignUpForm = () => {
  const [createTherapist, therapistCreateStates] = useCreateTherapistAccountMutation()

  return {
    isLoading: therapistCreateStates.isLoading,
    therapistCreateStates,
    createTherapist,
  }
}
