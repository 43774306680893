export const defaultValues = {
  email: '',
  full_name: '',
  birthday: '',
  phone_number: '',
  country: '',
  languages: [],
  professional_qualities: '',
  experience: '',
  education_level: '',
  licence: [],
  expertise: [],
  clinical_services_provided: [],
  current_status: '',
  job_advantages: '',
  age_groups_served: '',
  custom_qualification: '',
  custom_licence: '',
  custom_expertise: '',
  custom_services: '',
  custom_advant: '',
  bio: '',
}
