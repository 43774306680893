import styled from 'styled-components'

export const Button = styled('button')({
  width: 'auto',
  color: 'white',
  backgroundColor: '#8450A0',
  borderRadius: '50px',
  padding: '10px 20px',
  border: 'none',
  cursor: 'pointer',
  height: '40px',
  fontWeight: 'bold',
  fontSize: '14px',
})

export const TextButton = styled('button')({
  width: 'auto',
  display: 'flex',
  alignItems: 'center',
  color: '#8450A0',
  backgroundColor: 'transparent',
  borderRadius: '50px',
  border: 'none',
  cursor: 'pointer',
  fontSize: '14px',
  gap: '10px',
})
