import styled from 'styled-components'
import { fontSize, fontWeight, textAlign, variant } from 'styled-system'
import type { TextProps } from './types'
import { commonComposes } from '../common'

export const Text = styled('p')<TextProps>(
  variant({
    variants: {
      body1: {
        fontWeight: 600,
        fontSize: '16px',
        // lineHeight: '22px',
        fontFamily: 'Poppins_SemiBold, sans-serif',
      },
      body2: {
        fontWeight: 500,
        fontSize: '16px',
        // lineHeight: '22px',
        fontFamily: 'Poppins_Medium_500, sans-serif',
      },
      body3: {
        fontWeight: 400,
        fontSize: '16px',
        // lineHeight: '22px',
        fontFamily: 'Poppins_Regular, sans-serif',
      },
      body4: {
        fontWeight: 600,
        fontSize: '14px',
        // lineHeight: '20px',
        fontFamily: 'Poppins_SemiBold, sans-serif',
      },
      body5: {
        fontWeight: 500,
        fontSize: '14px',
        fontFamily: 'Poppins_Medium_500, sans-serif',
      },
      body6: {
        fontWeight: 400,
        fontSize: '14px',
        fontFamily: 'Poppins_Regular, sans-serif',
        color: 'textGray',
      },
    },
  }),
  commonComposes([textAlign, fontWeight, fontSize]),
)
