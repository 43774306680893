import { useCallback, useEffect } from 'react'
import { debounce } from 'lodash'

export const useDebounce = (functionToCall, value, watcher) => {
  const debounced = useCallback(
    debounce(() => {
      functionToCall(value)
    }, 1000),
    [],
  )

  useEffect(() => {
    if (value) {
      debounced()
    }
  }, [watcher, debounce])

  return {
    debounced,
  }
}
