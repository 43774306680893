// Core
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
// Actions
import { adminActions } from '../../../actions'
// Styles
import Styles from './styles.module.scss'
// Components
import { NoDataComponent, CustomPagination } from '../../GeneralComponents'
import { InputBase } from '@mui/material'
import { Table } from '../../Applies/Table'
import { Spinner } from '../../ChatSystem/Common/Spinner'
// Icons
import { ReactComponent as SearchIcon } from '../../../theme/assets/icons/search_convex.svg'
//Utils
import { paginateArray } from '../../../utils'

/**
 * Renders the ParentsAdmin component which manages and displays a list of parent clients.
 * @returns {JSX.Element} The rendered JSX element representing the ParentsAdmin component.
 */
export const ParentsAdmin = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const loading = useSelector((state) => state.general.loading)
  const user = useSelector((state) => state.auth.user)
  const allParents = useSelector((state) => state.admin.allParents)

  const [parents, setParents] = useState([])

  const [refetch, setRefetch] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)

  /**
   * Fetches all parent clients when `refetch` or `user` changes.
   */
  useEffect(() => {
    const data = {
      token: user?.token,
    }
    dispatch(adminActions.getAllParents(data))
  }, [refetch, user])

  /**
   * Paginates the `allParents` array and updates `parents` state based on `currentPage`.
   */
  useEffect(() => {
    const paginatedData = paginateArray(allParents)
    if (paginatedData.length) {
      setParents([...(paginatedData[currentPage - 1] || 0)])
    } else {
      setParents([])
    }
  }, [allParents, currentPage])

  const headers = [
    { text: 'NAME OF CLIENTS', sorting: true },
    { text: 'PHONE NUMBER', sorting: false },
    { text: 'PROFILES', sorting: false },
    { text: 'ADVISOR', sorting: false },
    { text: 'Receive Promo email', sorting: false },
    { text: 'Promo Code', sorting: false },
    { text: 'Promo code used', sorting: false },
    { text: ' ', sorting: false },
  ]

  /**
   * Navigates to the profile page of the selected child client.
   * @param {string} childId - The ID of the child client to navigate to.
   */
  const handleGoToProfile = (childId) => {
    navigate(`${childId}`)
  }

  const onClickEvents = {
    goToProfile: handleGoToProfile,
  }

  /**
   * Handles filtering of parent clients based on input value.
   * Updates the `parents` state with filtered and paginated data.
   * @param {Event} e - The input change event containing the updated value.
   */
  const handleChangeInput = (e) => {
    const inputValue = e.target.value.toLowerCase()

    const filteredArray = allParents?.filter((parent) => parent.user.full_name.toLowerCase().includes(inputValue) || parent.user.email.toLowerCase().includes(inputValue))

    const paginatedData = paginateArray(filteredArray)
    if (paginatedData.length) {
      setParents([...(paginatedData[currentPage - 1] || 0)])
    } else {
      setParents([])
    }
  }

  return (
    <div className={Styles.main_conatiner}>
      <div className={Styles.header}>
        <h6>Clients</h6>
        <div className={Styles.header__functions}>
          <div className={Styles.header__search}>
            <div className={Styles.header__search__container}>
              <SearchIcon />
              <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Search by name or email" onChange={(e) => handleChangeInput(e)} className={Styles.header__search__container__text} />
            </div>
          </div>
        </div>
      </div>

      {loading ? (
        <Spinner />
      ) : allParents.length ? (
        <div className={Styles.data_container}>
          <Table headers={headers} data={parents} userType={user?.user?.user_type} userDataType={'parent'} onClickEvents={onClickEvents} />
        </div>
      ) : (
        <NoDataComponent text={'There are no parents yet.'} />
      )}
      <CustomPagination dataArray={allParents} currentPage={currentPage} setCurrentPage={setCurrentPage} />
    </div>
  )
}
