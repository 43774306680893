// Core
// Styles
import { MAIN_STATEMENTS_ANSWERS } from '../../../data/enums'
import { categoryLocals, commonLocals, translateFamilyDynamics, translateParentalWellbeing, translateQuestionnaire } from '../../../data/locale.data'
import Styles from './Styles.module.scss'
import { Modal } from '@mui/material'
import { AdminsParentOnBoardingReport } from '../../AdminsParentOnBoardingReport/AdminsParentOnBoardingReport'
import { IconWorld, CloseIcon } from '../../../theme/assets/icons'
import { useQuestionnaireHook } from './useQuestionnaireHook'
import { TextButton } from '../../Core/Button'
import { mandatory_questions_parent_onboarding } from '../../../data'
import { ModalContent, ModalContentContainer } from '../../Core'

export const ParentProfileQuestionnairePartWithTranslation = ({ questionnaire, hideReportGenerator, profile, style }) => {
  const {
    handleTranslate,
    onClose,
    onOpen,
    handleReportTranslate,
    handleRestReport,
    open,
    rightAligned,
    parentLanguage,
    // language = 'en',
    language,
    isReportArabic,
    reportLanguage,
  } = useQuestionnaireHook({
    profile,
    Styles,
  })
  return (
    <div className={Styles.main_container} style={style}>
      <div
        className={Styles.main_container__space_between}
        style={{
          direction: language === 'en' ? 'ltr' : 'rtl',
        }}
      >
        <div>
          <h6 className={rightAligned}>{commonLocals[language]?.['Questionnaire']}</h6>
          {parentLanguage === language && <p className={Styles.main_container__subtitle}>{commonLocals[language]?.['Now viewing in parents preferred language']}</p>}
        </div>
        <div className={Styles.main_container__flex__center}>
          <button onClick={handleTranslate} className={Styles.main_container__btn__secondary}>
            Translate
          </button>
          {!hideReportGenerator && (
            <button
              onClick={() => {
                handleRestReport()
                onOpen()
              }}
              className={Styles.main_container__btn}
            >
              Generate Report
            </button>
          )}
        </div>
      </div>
      <div className={`${Styles.main_container__questionnaire} ${rightAligned}`}>
        {questionnaire?.questionnaire?.slice(0, mandatory_questions_parent_onboarding?.length)?.map((section, index) => (
          <div className={Styles.main_container__questionnaire__item} key={section?.id}>
            <p>
              {section?.id + 1}. {categoryLocals[section?.category]?.[language]}
            </p>
            <span>{translateQuestionnaire[index]?.[section?.main_statement]?.[language]}</span>
            <div>
              <p>{commonLocals[language]?.[section?.main_statement_answer] || 'Skipped'}</p>
              {section?.advisor_comment && (
                <div className={Styles.advisor}>
                  <span>{commonLocals[language]?.['Advisor comment']}:</span> {section?.advisor_comment}
                </div>
              )}
            </div>
            {section?.main_statement_answer === MAIN_STATEMENTS_ANSWERS[0] && (
              <div className={Styles.main_container__questionnaire__item__sub}>
                {section?.sub_statements?.map((statement) => (
                  <div className={Styles.main_container__questionnaire__item__sub__item} key={statement?.id}>
                    <p>
                      {section?.id + 1}.{statement?.id + 1} {translateQuestionnaire[index]?.[statement?.statement]?.[language]}
                    </p>
                    <span>{commonLocals[language]?.[statement?.sub_statement_answer]}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
        {questionnaire?.familyDynamics && (
          <div className={Styles.optional_sections}>
            <h6>{categoryLocals['Family dynamics'][language]}</h6>
            {questionnaire?.familyDynamics?.map((section) => (
              <div className={Styles.optional_sections__item} key={section?.id}>
                <span>
                  {section?.id + 1}. {translateFamilyDynamics[section?.question]?.[language]}
                </span>
                <div>
                  <p>{commonLocals[language]?.[section?.answer]}</p>
                </div>
              </div>
            ))}
          </div>
        )}
        {questionnaire?.parentalWellbeing && (
          <div className={Styles.optional_sections}>
            <h6>{categoryLocals['Parental Wellbeing']?.[language]}</h6>
            {questionnaire?.parentalWellbeing?.map((section) => (
              <div className={Styles.optional_sections__item} key={section?.id}>
                <span>
                  {section?.id + 1}. {translateParentalWellbeing[section?.question]?.[language]}
                </span>
                <div>
                  <p>{commonLocals[language]?.[section?.answer]}</p>
                </div>
              </div>
            ))}
          </div>
        )}
        <div className={Styles.optional_sections}>
          <h6>{commonLocals?.[language]?.Additional}</h6>
          <p>{questionnaire?.questionnaire?.[mandatory_questions_parent_onboarding?.length]?.tell_us_more || commonLocals?.[language]?.Skipped}</p>
        </div>
      </div>
      <Modal open={open} onClose={onClose}>
        <ModalContentContainer>
          <ModalContent className={Styles.main_container}>
            <div style={{ flexDirection: isReportArabic ? 'row-reverse' : 'row' }} className={Styles.main_container__space_between}>
              <div>
                <p className={Styles.main_container__typography}>{commonLocals[reportLanguage]?.Report}</p>
                {parentLanguage === reportLanguage && (
                  <p className={Styles.main_container__subtitle}>{commonLocals[reportLanguage]?.['Now viewing in parents preferred language']}</p>
                )}
              </div>
              <div
                style={{
                  direction: reportLanguage === 'en' ? 'ltr' : 'rtl',
                }}
                className={Styles.main_container__flex__center}
              >
                <TextButton onClick={handleReportTranslate}>
                  <IconWorld width={20} /> <span>Translate</span>
                </TextButton>
                <CloseIcon style={{ alignSelf: 'flex-end', cursor: 'pointer' }} onClick={onClose} />
              </div>
            </div>
            <AdminsParentOnBoardingReport questionnaire={questionnaire} childName={profile?.full_name} language={reportLanguage} />
          </ModalContent>
        </ModalContentContainer>
      </Modal>
    </div>
  )
}
