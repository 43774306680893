/* eslint-disable no-useless-escape */
// Instruments
import moment from 'moment'
import Resizer from 'react-image-file-resizer'
import axios from 'axios'
import ShortUniqueId from 'short-unique-id'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
// Data
import { countries, education, services } from '../data'
//Styles
import Styles from './styles.module.scss'

dayjs.extend(utc)
dayjs.extend(timezone)

// Cookie

export const getCookie = (name) => {
  const matches = document.cookie.match(new RegExp(`(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`))

  return matches ? decodeURIComponent(matches[1]) : null
}

export const setCookie = (name, value, options = {}) => {
  options = {
    path: '/',
    ...options,
  }

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString()
  }

  let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`

  for (const optionKey in options) {
    updatedCookie += `; ${optionKey}`
    const optionValue = options[optionKey]
    if (optionValue !== true) {
      updatedCookie += `=${optionValue}`
    }
  }

  document.cookie = updatedCookie
}

export const deleteCookie = (name) => {
  setCookie(name, '', {
    'max-age': -1,
  })
}

// Get full country name

export const getCountryFullName = (id, type) => {
  if (!id || !id.trim()) return null

  const fullName = countries.find((item) => id === item?.value.split('_')[0])

  if (type) return fullName?.value

  return fullName?.value?.split('_')[1]
}

// Get array's strings

export const getArrayStrings = (arr) => {
  return arr.map((item) => {
    return item.value || item
  })
}

// Get age

export const getAge = (birth, type = 'year') => {
  if (!birth) return null

  const difference = moment() - moment(birth).valueOf()
  const age = moment.duration(difference, 'milliseconds').asYears()
  const ageMonths = moment.duration(difference, 'milliseconds').asMonths()

  return type === 'year' ? Math.floor(age) : Math.floor(ageMonths)
}

// Array to string

export const arrJoin = (arr) => {
  if (!arr || !arr.length) return null

  if (arr.length === 1) return arr[0]

  return arr.join(', ')
}

// Get radio value

export const getRadioFullValue = (value) => {
  if (!value) return null

  const educationValue = education.find((item) => item.title === value)
  const servicesValue = services.find((item) => item.title === value)

  return educationValue ? educationValue?.label : servicesValue?.label
}

// File to Base46

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

// Base46 to File

export const toFile = (url, filename, mimeType) => {
  return fetch(url)
    .then((res) => res.arrayBuffer())
    .then((buf) => new File([buf], filename, { type: mimeType }))
}

//   Create avatar

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.src = url
  })

const toBlob = (canvas) =>
  new Promise((resolve) => {
    canvas.toBlob(resolve)
  })

const resizeImage = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      'JPEG',
      90,
      0,
      (uri) => {
        resolve(uri)
      },
      'base64',
    )
  })

export const getCroppedImg = async (data) => {
  const image = await createImage(data.imgUrl)
  const canvas = document.createElement('canvas')
  canvas.width = data.pixelCrop.width
  canvas.height = data.pixelCrop.height
  const ctx = canvas.getContext('2d')

  ctx.fillStyle = '#ffffff'
  ctx.fillRect(0, 0, canvas.width, canvas.height)
  ctx.drawImage(image, data.pixelCrop.x, data.pixelCrop.y, data.pixelCrop.width, data.pixelCrop.height, 0, 0, data.pixelCrop.width, data.pixelCrop.height)

  const blob = await toBlob(canvas)
  const croppedImgUrl = await resizeImage(blob)

  return croppedImgUrl
}

// Download file

export const downloadFile = (url, fileName) => {
  axios({
    method: 'GET',
    url,
    decompress: false,
    responseType: 'blob',
    mode: 'cors',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Methods': 'GET',
      Accept: '*/*',
    },
  }).then((response) => {
    const urla = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    const name = fileName === 'zip' ? url.split('?')[0].split('zip/')[1].replaceAll('%', '_') : fileName
    link.href = urla
    link.setAttribute('download', name || 'files')
    link.setAttribute('target', '_blank')
    document.body.appendChild(link)
    link.click()
  })
}

export const transactionLink = (url) => {
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('target', '_blank')
  document.body.appendChild(link)
  link.click()
}

/**
 * for unique id
 * @example uid.stamp(16)
 * */
export const uid = new ShortUniqueId({ length: 10 })

export const findErrorElement = (data) => {
  for (let i = 0; i < data.length; i++) {
    // console.log(data[i]?.lastChild?.lastChild?.lastChild?.innerHTML)
    if (data[i]?.lastChild?.lastChild?.lastChild?.innerHTML === 'Please, provide an answer to the question') {
      return i
    }
  }

  return -1
}

// Get format today

export const todayF = moment().format('YYYY-MM-DD')

// Convert bytes
export const convertBytes = (bytes) => {
  if (bytes >= 1024 * 1024 * 1024) {
    // If file size is at least 1 GB, show size in GB with two decimal places
    const convertedValue = bytes / (1024 * 1024 * 1024)
    return convertedValue.toFixed(2) + ' GB'
  } else if (bytes >= 1024 * 1024) {
    // If file size is at least 1 MB, show size in MB with two decimal places
    const convertedValue = bytes / (1024 * 1024)
    return convertedValue.toFixed(2) + ' MB'
  } else {
    // For files smaller than 1 MB, show size in KB with two decimal places
    const convertedValue = bytes / 1024
    return convertedValue.toFixed(2) + ' KB'
  }
}
import { ReactComponent as NewFile } from '../theme/assets/icons/file_new.svg'

export const getPreviewImg = (Styles) => {
  return (
    <div className={Styles.preview__item__preview__imgWrap}>
      <NewFile />
    </div>
  )
}

export const getDate = (dateString, isNeedTime, timeOnly) => {
  const date = dayjs(dateString).tz(dayjs.tz.guess())
  const formattedDate = date.format('DD/MM/YYYY')
  const formattedTime = date.format('h:mm A')
  const timezoneFormatted = `GMT${date.format('Z')}`

  if (isNeedTime) {
    return `${formattedDate} ${formattedTime}`
  } else if (timeOnly) {
    return `${formattedTime} (${timezoneFormatted})`
  } else {
    return formattedDate
  }
}

export const getInitials = (name) => {
  if (name) {
    const [firstName, lastName] = name.split(' ')

    if (firstName && lastName) {
      const firstInitial = firstName[0]
      const lastInitial = lastName[0]
      return firstInitial.toUpperCase() + lastInitial.toUpperCase()
    } else {
      return 'UN'
    }
  } else {
    return 'UN'
  }
}

export function formatPhoneNumber(phoneNumber) {
  const cleanPhoneNumber = phoneNumber.replace(/\D/g, '')

  const countryCode = cleanPhoneNumber.slice(0, 2)

  const phoneNumberDigits = cleanPhoneNumber.slice(-10)

  let formattedPhoneNumber = `+${countryCode} `

  for (let i = 0; i < phoneNumberDigits.length; i += 3) {
    formattedPhoneNumber += `${phoneNumberDigits.slice(i, i + 3)} `
  }

  return formattedPhoneNumber.trim()
}

export const returnCountry = (countryCode, propsStyles) => {
  let countryName = ''
  countries.forEach((country) => {
    if (country.value.includes(countryCode)) {
      countryName = country.value.split('_')[1]
    }
  })
  return (
    <div className={`${Styles.country_container} ${propsStyles?.country_container}`}>
      {countryCode && <img width={20} src={`https://flagcdn.com/w20/${countryCode.toLowerCase()}.png`} alt="flag" />}
      <span>{countryName}</span>
    </div>
  )
}

export const capitalizeFirstLetter = (str) => {
  if (!str) {
    return ''
  }
  console.log(str)

  const capitalizedStr = str?.charAt(0)?.toUpperCase() + str?.slice(1)?.toLowerCase()
  return capitalizedStr
}

export const returnContent = (content) => {
  const regex = /(https?:\/\/\S+)/g
  const matches = content.match(regex)

  if (matches) {
    const regex = /(https?:\/\/\S+)/g
    const replacedContent = content.replace(regex, '<a href="$1" class="custom_link" target="_blank">$1</a>')
    return <div className={Styles.custom_link} dangerouslySetInnerHTML={{ __html: replacedContent }} />
  } else {
    return <span>{content}</span>
  }
}

const getField = (obj, fieldKey) => {
  return fieldKey.split('.').reduce((o, key) => (o || {})[key], obj)
}

export const sortArray = (descending, array, fieldName) => {
  let fieldKey
  switch (fieldName) {
    case 'ADMIN_ADVISOR_DATE CREATED':
      fieldKey = 'user.created_at'
      break
    case 'ADMIN_ADVISOR_NAME OF APPLIER':
      fieldKey = 'user.full_name'
      break
    case 'ADMIN_ADVISOR_COUNTRY':
      fieldKey = 'user.country'
      break

    case 'ADMIN_THERAPIST_FULL NAME':
      fieldKey = 'user.full_name'
      break
    case 'ADMIN_THERAPIST_QUALIFICATION':
      fieldKey = 'professional_qualities'
      break
    case 'ADMIN_THERAPIST_COUNTRY':
      fieldKey = 'user.country'
      break

    case 'ADMIN_PARENT_NAME OF APPLIER':
      fieldKey = 'user.full_name'
      break

    case 'THERAPIST_APPLICATION_DATE CREATED':
      fieldKey = 'created_at'
      break
    case 'THERAPIST_APPLICATION_NAME OF APPLIER':
      fieldKey = 'child_name'
      break
    case 'THERAPIST_APPLICATION_PROBLEM':
      fieldKey = 'concerns'
      break
    case 'THERAPIST_APPLICATION_STATUS':
      fieldKey = 'therapist_status'
      break

    case 'THERAPIST_PATIENT_DATE CREATED':
      fieldKey = 'created_at'
      break
    case 'THERAPIST_PATIENT_NAME OF APPLIER':
      fieldKey = 'full_name'
      break
    case 'THERAPIST_PATIENT_PROBLEM':
      fieldKey = 'concerns'
      break
    case 'THERAPIST_PATIENT_MEETING TIME':
      fieldKey = 'calcom_session.start_time'
      break

    case 'ADVISOR_APPLICATION_DATE CREATED':
      fieldKey = 'created_at'
      break
    case 'ADVISOR_APPLICATION_NAME OF APPLIER':
      fieldKey = 'full_name'
      break
    case 'ADVISOR_APPLICATION_PHONE':
      fieldKey = 'phone_number'
      break
    case 'ADVISOR_APPLICATION_PROBLEM':
      fieldKey = 'concerns'
      break
    case 'ADVISOR_APPLICATION_STATUS':
      fieldKey = 'status'
      break
    case 'ADVISOR_APPLICATION_MEETING TIME':
      fieldKey = 'start_time'
      break

    case 'ADVISOR_PARENT_NAME OF PROFILE':
      fieldKey = 'profile_name'
      break
    case 'ADVISOR_PARENT_DATE CREATED':
      fieldKey = 'created_at'
      break
    case 'ADVISOR_PARENT_STATUS':
      fieldKey = 'status'
      break

    case 'ADVISOR_THERAPIST_QUALIFICATION':
      fieldKey = 'professional_qualities'
      break
    case 'ADVISOR_THERAPIST_FULL NAME':
      fieldKey = 'user.full_name'
      break
    case 'ADVISOR_THERAPIST_COUNTRY':
      fieldKey = 'user.country'
      break
    case 'ADVISOR_THERAPIST_LANGUAGE':
      fieldKey = 'languages'
      break
    case 'ADVISOR_THERAPIST_TIMEZONE':
      fieldKey = 'user.time_zone'
      break

    default:
      fieldKey = null
      break
  }

  if (!fieldKey) return array

  array?.sort((a, b) => {
    let keyA = getField(a, fieldKey)
    let keyB = getField(b, fieldKey)

    if (typeof keyA !== 'string' || typeof keyB !== 'string') {
      keyA = keyA[0]
      keyB = keyB[0]
    }

    let comparison = 0

    if (keyA.toLowerCase() > keyB.toLowerCase()) {
      comparison = 1
    } else if (keyA.toLowerCase() < keyB.toLowerCase()) {
      comparison = -1
    }

    return descending ? -comparison : comparison
  })

  return array
}

export const returnStatus = (status) => {
  let style
  let statusName
  switch (status) {
    case 'planned': {
      statusName = 'Planned'
      style = { background: '#F6F0FC', color: '#8450a0' }
      break
    }
    case 'in_a_meeting': {
      statusName = 'In a Meeting'
      style = { background: '#FFFBE4', color: '#B19607' }
      break
    }
    case 'waiting': {
      statusName = 'Waiting'
      style = { background: '#FFFBE4', color: '#B19607' }
      break
    }
    case 'declined': {
      statusName = 'Declined'
      style = { background: '#F8F8FB', color: '#626262' }
      break
    }
    case 'accepted': {
      statusName = 'Accepted'
      style = { background: '#F8FFEB', color: '#3B8400' }
      break
    }
    case 'approved': {
      statusName = 'Approved'
      style = { background: '#F8FFEB', color: '#3B8400' }
      break
    }
    case 'active': {
      statusName = 'Active'
      style = { background: '#F8FFEB', color: '#3B8400' }
      break
    }
    case 'failed': {
      statusName = 'Failed'
      style = { background: '#FFE4E4', color: '#E51616' }
      break
    }
    default: {
      statusName = ''
      style = {}
      break
    }
  }
  return (
    <div className={Styles.status_container} style={style}>
      {statusName.toUpperCase()}
    </div>
  )
}

export const returnLanguagesArray = (language) => {
  switch (language) {
    case 'English':
      return 'en'
    case 'Arabic':
      return 'ar'
    case 'French':
      return 'fr'
    case 'Spanish':
      return 'es'
    case 'German':
      return 'de'
    case 'Hindi':
      return 'hi'
    case 'Bengali':
      return 'bn'
    case 'Tagalog':
      return 'tl'
    case 'Urdu':
      return 'ur'
    case 'Other':
      return 'ot'
    default:
      return null
  }
}

export const returnLanguagesFromArray = (language) => {
  switch (language) {
    case 'en':
      return 'English'
    case 'ar':
      return 'Arabic'
    case 'fr':
      return 'French'
    case 'es':
      return 'Spanish'
    case 'de':
      return 'German'
    case 'hi':
      return 'Hindi'
    case 'bn':
      return 'Bengali'
    case 'tl':
      return 'Tagalog'
    case 'ur':
      return 'Urdu'
    case 'ot':
      return 'Other'
  }
}

export const returnEducationalLevel = (educationLevel) => {
  switch (educationLevel) {
    case 'Bachelors Level':
      return 'bl'
    case 'Masters Level':
      return 'ml'
    case 'Doctoral Level':
      return 'dl'
  }
}

export const returnEducationalLevelForClient = (educationLevel) => {
  switch (educationLevel) {
    case 'bl':
      return 'Bachelors Level'
    case 'ml':
      return 'Masters Level'
    case 'dl':
      return 'Doctoral Level'
  }
}

export const returnCurrentyActivity = (status) => {
  switch (status) {
    case 'Yes, full time':
      return 'ft'
    case 'Yes, part time':
      return 'pt'
    case 'No, I am not active clinically':
      return 'na'
  }
}

export const returnCurrentyActivityForClient = (status) => {
  switch (status) {
    case 'ft':
      return 'Yes, full time'
    case 'pt':
      return 'Yes, part time'
    case 'na':
      return 'No, I am not active clinically'
  }
}

export const formatDateToReadable = (inputDateStr) => {
  const inputDate = new Date(inputDateStr)

  const day = inputDate.getDate()
  const month = inputDate.getMonth() + 1
  const year = inputDate.getFullYear()

  const formattedDate = `${day}/${month}/${year}`

  return formattedDate
}

export const formatDateToString = (inputDateStr) => {
  if (!inputDateStr) {
    return ''
  }
  const [day, month, year] = inputDateStr.split('/')

  const outputDate = new Date(`${month}/${day}/${year} 00:00:00 GMT+0000`)

  return outputDate.toString()
}

export const paginateArray = (array) => {
  return array.reduce((resultArray, item, index) => {
    const pageIndex = Math.floor(index / 8)
    if (!resultArray[pageIndex]) {
      resultArray[pageIndex] = []
    }
    resultArray[pageIndex].push(item)
    return resultArray
  }, [])
}

export const convertDate = (inputDate) => {
  if (!inputDate) {
    return null
  }
  const inputFormat = 'YYYY-MM-DDTHH:mm:ss[Z]'
  const outputFormat = 'ddd DD MMMM, YYYY'

  const momentObj = moment.utc(inputDate, inputFormat)
  const outputDate = momentObj.format(outputFormat)

  return outputDate
}

export const convertTime = (inputTime) => {
  const inputFormat = 'YYYY-MM-DDTHH:mm:ss[Z]'
  const outputFormat = 'HH:mm'

  const momentObj = moment.utc(inputTime, inputFormat)
  const outputTime = momentObj.local().format(outputFormat)

  return outputTime
}

export const getMinutesBeforeEvent = (inputDate) => {
  const targetDate = new Date(inputDate)
  const currentDate = new Date()

  const timeDifference = targetDate - currentDate

  const minutesDifference = Math.floor(timeDifference / (1000 * 60))

  return minutesDifference
}

export const getLocalStorage = (name) => {
  return localStorage.getItem(name)
}

export const setLocalStorage = (name, value) => {
  localStorage.setItem(name, value)
}

export const deleteLocalStorage = (name) => {
  localStorage.removeItem(name)
}
