import { apiRoutes } from '../../../../apiRoutes'
import { rootServiceApi } from '../../../../store/service'

export const therapistServices = rootServiceApi.injectEndpoints({
  endpoints: (build) => ({
    createTherapistAccount: build.mutation({
      query: ({ therapistInfo, documents }) => {
        const formData = new FormData()
        formData.append('therapistInfo', JSON.stringify(therapistInfo))

        const fileTypes = ['personal', 'educational', 'professional']

        formData.append('fileTypes', JSON.stringify(fileTypes))

        if (Array.isArray(documents)) {
          documents.forEach((document) => {
            formData.append(`files`, document?.file)
          })
        }

        return {
          url: apiRoutes.therapist.create,
          body: formData,
          method: 'POST',
          formData: true,
        }
      },
    }),
  }),
})

export const { useCreateTherapistAccountMutation } = therapistServices
