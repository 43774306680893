import { createTheme } from '@mui/material/styles'

export const muiTheme = createTheme({
  breakpoints: {
    values: {
      xs: 350,
      sm: 500,
      md: 700,
      lg: 900,
      xl: 1200,
    },
  },

  palette: {
    primary: {
      main: '#e59693',
      light: '#eca9a4',
      dark: '#fdd9d6',
    },
    secondary: {
      main: '#8450a0',
      light: '#6d308d',
    },
    error: {
      main: '#dc3545',
    },
    warning: {
      main: '#ffc107',
    },
    success: {
      main: '#7fc8a7',
    },
    text: {
      primary: '#171717',
      secondary: '#9b9b9b',
    },
    background: {
      default: '#fafafa',
      paper: '#ffffff',
    },
  },
  typography: {
    fontFamily: 'Poppins, Arial, sans-serif',
    fontSize: 14,
  },
  shape: {
    borderRadius: 8,
  },
})
