import { apiRoutes } from '../../../apiRoutes'
import { rootServiceApi } from '../../../store/service'
import { getCookie } from '../../../utils'

export const checkEmailExistenceServiceForAuthenticatedUser = rootServiceApi.injectEndpoints({
  endpoints: (build) => ({
    checkEmailUser: build.mutation({
      query: ({ email, token }) => {
        return {
          url: apiRoutes.user.checkEmailForAuthenticated,
          method: 'POST',
          body: {
            email,
          },
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      },
    }),
  }),
})

export const { useCheckEmailUserMutation } = checkEmailExistenceServiceForAuthenticatedUser
