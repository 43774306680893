// Core
// Styles
import { mandatory_questions_parent_onboarding } from '../../../data'
import { MAIN_STATEMENTS_ANSWERS } from '../../../data/enums'
import Styles from './Styles.module.scss'

export const ParentProfileQuestionnairePart = ({ questionnaire }) => {
  console.log(questionnaire?.questionnaire)
  return (
    <div className={Styles.main_container}>
      <h6>Questionnaire</h6>
      <div className={Styles.main_container__questionnaire}>
        {questionnaire?.questionnaire?.map((section) => (
          <div className={Styles.main_container__questionnaire__item} key={section?.id}>
            <p>
              {section?.id + 1}. {section?.category}
            </p>
            <span>{section?.main_statement}</span>
            <div>
              <p>{section?.main_statement_answer || 'Skipped'}</p>
              {section?.advisor_comment && (
                <div className={Styles.advisor}>
                  <span>Advisor comment:</span> {section?.advisor_comment}
                </div>
              )}
            </div>
            {section?.main_statement_answer === MAIN_STATEMENTS_ANSWERS[0] && (
              <div className={Styles.main_container__questionnaire__item__sub}>
                {section?.sub_statements?.map((statement) => (
                  <div className={Styles.main_container__questionnaire__item__sub__item} key={statement?.id}>
                    <p>
                      {section?.id + 1}.{statement?.id + 1} {statement?.statement}
                    </p>
                    <span>{statement?.sub_statement_answer}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
        {questionnaire?.familyDynamics && (
          <div className={Styles.optional_sections}>
            <h6>Family dynamics</h6>
            {questionnaire?.familyDynamics?.map((section) => (
              <div className={Styles.optional_sections__item} key={section?.id}>
                <span>
                  {section?.id + 1}. {section?.question}
                </span>
                <div>
                  <p>{section?.answer}</p>
                </div>
              </div>
            ))}
          </div>
        )}
        {questionnaire?.parentalWellbeing && (
          <div className={Styles.optional_sections}>
            <h6>Parental Wellbeing</h6>
            {questionnaire?.parentalWellbeing?.map((section) => (
              <div className={Styles.optional_sections__item} key={section?.id}>
                <span>
                  {section?.id + 1}. {section?.question}
                </span>
                <div>
                  <p>{section?.answer}</p>
                </div>
              </div>
            ))}
          </div>
        )}
        <div className={Styles.optional_sections}>
          <h6>Additional information</h6>
          <p>{questionnaire?.questionnaire?.[mandatory_questions_parent_onboarding?.length]?.tell_us_more || 'Skipped'}</p>
        </div>
      </div>
    </div>
  )
}
