import { rootServiceApi } from '../../../../store/service'

export const promoCodeService = rootServiceApi.injectEndpoints({
  endpoints: (build) => ({
    checkPromoCode: build.mutation({
      query: (promo_code) => ({
        url: 'promo_code/checkPromoCode/',
        method: 'POST',
        body: {
          promo_code,
        },
      }),
    }),
  }),
})

export const { useCheckPromoCodeMutation } = promoCodeService
