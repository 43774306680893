export const translateTherapistStatuses = {
  accepted: {
    en: 'accepted',
    ar: 'قبلت',
  },
  rejected: {
    en: 'rejected',
    ar: 'مرفوض',
  },
}

export const translateTherapistProfQualities = {
  'Learning Specialist': {
    en: 'Learning Specialist',
    ar: 'أخصائي التعلم',
  },
  'Speech and Language Therapist': {
    en: 'Speech and Language Therapist',
    ar: 'معالج نطق ولغة',
  },
  Psychologist: {
    en: 'Psychologist',
    ar: 'طبيب نفساني',
  },
  'Behavior Analyst': {
    en: 'Behavior Analyst',
    ar: 'محلل سلوكي',
  },
  'Physical Therapist': {
    en: 'Physical Therapist',
    ar: 'معالج طبيعي',
  },
}

export const translateLanguages = {
  Hindi: {
    en: 'Hindi',
    ar: 'الهندية',
  },
}

export const translateRecordStatuses = {
  Completed: {
    en: 'Completed',
    ar: 'مكتمل',
  },
  Planned: {
    en: 'Planned',
    ar: 'المخطط لها',
  },
  'Pending Payment': {
    en: 'Pending Payment',
    ar: 'في انتظار الدفع',
  },
  'In progress': {
    en: 'In progress',
    ar: 'في تَقَدم',
  },
  'Canceled - Payment Canceled': {
    en: 'Canceled - Payment Canceled',
    ar: 'تم الإلغاء - تم إلغاء الدفع',
  },
  'Canceled - Payment Expired': {
    en: 'Canceled - Payment Expired',
    ar: 'تم الإلغاء - انتهت صلاحية الدفع',
  },
  'Canceled - Payment Declined': {
    en: 'Canceled - Payment Declined',
    ar: 'تم الإلغاء - تم رفض الدفع',
  },
}

export const translateMainAnswersQuestionnaire = {
  en: {
    0: 'Yes',
    1: 'No',
  },
  ar: {
    0: 'نعم',
    1: 'لا',
  },
}

export const translateSubAnswersQuestionnaire = {
  en: {
    0: 'Not at all',
    1: 'Sometimes',
    2: 'Often',
  },
  ar: {
    0: 'مطلقاً',
    1: 'أحيانا',
    2: 'غالباً',
  },
}

export const translateQuestionnaire = {
  0: {
    'My child often seems sad, anxious, or complains of physical pains': {
      en: 'My child often seems sad, anxious, or complains of physical pains',
      ar: 'غالباً ما يكون طفلي حزيناً او قلقاً، او يشتكي من آلام جسدية',
    },
    'Worries without clear reasons': {
      en: 'Worries without clear reasons',
      ar: 'يقلق دون سبب واضح',
    },
    'Often looks sad or tearful': {
      en: 'Often looks sad or tearful',
      ar: 'يبدو حزينًا أو يبكي أكثر من المعتاد',
    },
    'Complains of headaches or stomach pains without clear cause': {
      en: 'Complains of headaches or stomach pains without clear cause',
      ar: 'يشتكي من الصداع أو آلام المعدة دون سبب واضح',
    },
    'Feels hopeless': {
      en: 'Feels hopeless',
      ar: 'يشعر باليأس',
    },
    'Loses interest in previously enjoyed activities': {
      en: 'Loses interest in previously enjoyed activities',
      ar: 'يفقد الاهتمام بالأنشطة التي كان يستمتع بها سابقًا',
    },
    'Exhibits sudden and intense irritability or anger that is difficult to manage': {
      en: 'Exhibits sudden and intense irritability or anger that is difficult to manage',
      ar: 'ينفجر بغضب فجأة بطريقة لا يمكن التحكم فيها',
    },
    'Shows increased clinginess or distress when separated from their caregivers': {
      en: 'Shows increased clinginess or distress when separated from their caregivers',
      ar: 'يزداد تعلقه بمقدمي الرعاية أو يشعر بالضيق عند الانفصال عنهم',
    },
  },
  1: {
    'My child struggles with following rules and can be challenging': {
      en: 'My child struggles with following rules and can be challenging',
      ar: ' يواجه طفلي صعوبة في اتباع القواعد وقد يكون تحدياً',
    },
    'Resists following rules or instructions, manifested as arguing with adults or throwing temper tantrums': {
      en: 'Resists following rules or instructions, manifested as arguing with adults or throwing temper tantrums',
      ar: 'يقاوم اتباع القواعد او التعليمات يتصف في الجدل مع البالغين و إلقاء نوبات غضب',
    },
    'Shows anger or resentment with changes in routine or during transitions': {
      en: 'Shows anger or resentment with changes in routine or during transitions',
      ar: 'يظهر علامات الغضب أو الاستياء، ويواجه صعوبات في التأقلم مع التغييرات',
    },
    'Blames others for their mistakes': {
      en: 'Blames others for their mistakes',
      ar: 'يلوم الآخرين على أخطائه وسلوكياته الخاطئة',
    },
    'Has been noticed lying or stealing': {
      en: 'Has been noticed lying or stealing',
      ar: 'لوحظ أنه يكذب أو يسرق',
    },
    'Displays aggression (verbal or physical)': {
      en: 'Displays aggression (verbal or physical)',
      ar: 'يظهر سلوكيات عدوانية أو عدائية تجاه الآخرين، بما في ذلك العدوان اللفظي والجسدي',
    },
    'Acts defiantly, challenging authority figures': {
      en: 'Acts defiantly, challenging authority figures',
      ar: 'يبدي سلوكاً معارضاً أو متحدياً ويقاوم السلطة',
    },
  },
  2: {
    'My child is very active and/or has trouble focusing': {
      en: 'My child is very active and/or has trouble focusing',
      ar: 'البيان الرئيسي: طفلي نشط كثيراً ويواجه صعوبة في التركيز',
    },
    "Constantly fidgets or can't stay seated": {
      en: "Constantly fidgets or can't stay seated",
      ar: 'يتململ باستمرار او لا يستطيع البقاء جالسا',
    },
    'Has difficulty waiting for their turn in games or group activities': {
      en: 'Has difficulty waiting for their turn in games or group activities',
      ar: 'يواجه صعوبة في انتظار دوره في الألعاب أو الأنشطة الجماعية',
    },
    "Interrupts or can't wait to speak in conversations": {
      en: "Interrupts or can't wait to speak in conversations",
      ar: 'يقاطع الآخرين وليس لديه القدرة على الانتظار للكلام في المحادثات',
    },
    'Is constantly in motion': {
      en: 'Is constantly in motion',
      ar: 'دائم الحركة',
    },
    'Quickly loses focus on tasks and moves from one activity to another': {
      en: 'Quickly loses focus on tasks and moves from one activity to another',
      ar: 'يفقد التركيز بسرعة في المهام، وينتقل من نشاط الى آخر',
    },
    'Makes careless mistakes in schoolwork or other activities': {
      en: 'Makes careless mistakes in schoolwork or other activities',
      ar: 'يقوم بأخطاء غير مقصودة في الواجبات المدرسية أو الأنشطة الأخرى',
    },
    'Is easily distracted': {
      en: 'Is easily distracted',
      ar: 'يتشتت بسهولة',
    },
    'Avoids or dislikes tasks that need sustained effort': {
      en: 'Avoids or dislikes tasks that need sustained effort',
      ar: 'يتجنب أو لا يحب المهام التي تتطلب جهداً ذهنياً مستمراً',
    },
    'Loses things': {
      en: 'Loses things',
      ar: 'يفقد الأشياء',
    },
  },
  3: {
    'My child has difficulties in social situations, often feels left out, or prefers to be alone': {
      en: 'My child has difficulties in social situations, often feels left out, or prefers to be alone',
      ar: 'البيان الرئيسي: يواجه طفلي صعوبات في المواقف الاجتماعية وغالبًا ما يشعر بالاستبعاد أو يفضل البقاء وحيدًا',
    },
    'Has trouble making and keeping friends': {
      en: 'Has trouble making and keeping friends',
      ar: 'يجد صعوبة في تكوين والحفاظ على الصداقات',
    },
    'Seems to be left out or gets teased by other kids': {
      en: 'Seems to be left out or gets teased by other kids',
      ar: 'يبدو مستبعدًا أو يتعرض للمضايقة من قبل الأطفال الآخرين أثناء اللعب',
    },
    'Struggles to understand social cues': {
      en: 'Struggles to understand social cues',
      ar: 'يجد صعوبة في فهم الإشارات الاجتماعية والتصرف بشكل مناسب عند التواجد مع الآخرين',
    },
    'May dominate conversations or not participate in dialogue': {
      en: 'May dominate conversations or not participate in dialogue',
      ar: 'قد يسيطر على المحادثات أو لا يشارك بشكل فعّال في الحوار',
    },
    'Does not start conversations or play activities': {
      en: 'Does not start conversations or play activities',
      ar: 'لا يبادر ببدء المحادثات أو الأنشطة اللعبية مع الأطفال الآخرين',
    },
    'Shows limited interest in engaging with other children': {
      en: 'Shows limited interest in engaging with other children',
      ar: 'يبدي اهتمامًا محدودًا في التفاعل مع الأطفال الآخرين',
    },
    'Finds it challenging to follow social rules, like taking turns': {
      en: 'Finds it challenging to follow social rules, like taking turns',
      ar: 'يجد صعوبة في اتباع القواعد الاجتماعية الشائعة، مثل أخذ الدور في التناوب أو المشاركة',
    },
    "Seems indifferent to others' feelings": {
      en: "Seems indifferent to others' feelings",
      ar: 'يبدو غير مبالٍ بمشاعر الآخرين',
    },
    'Does not notice when others are hurt or upset': {
      en: 'Does not notice when others are hurt or upset',
      ar: 'لا يلاحظ عندما يتأذى الآخرون أو يشعرون بالضيق',
    },
  },
  4: {
    'My child has trouble with movement skills': {
      en: 'My child has trouble with movement skills',
      ar: 'البيان الرئيسي: طفلي لديه مشاكل في مهارات الحركة',
    },
    'Has trouble with small movements, like cutting with scissors or drawing': {
      en: 'Has trouble with small movements, like cutting with scissors or drawing',
      ar: 'يواجه صعوبة في الحركات الصغيرة، مثل قص الورق بالمقص او الرسم',
    },
    'Has trouble with large movements, like running, jumping, or balancing': {
      en: 'Has trouble with large movements, like running, jumping, or balancing',
      ar: 'يواجه صعوبة في الحركات الكبيرة، مثل الركض، القفز او التوازن',
    },
  },
  5: {
    'My child has trouble with various thinking or learning tasks': {
      en: 'My child has trouble with various thinking or learning tasks',
      ar: 'البيان الرئيسي: طفلي يواجه صعوبات في مختلف المهام الفكرية والتعليمية',
    },
    'Struggles with reasoning or solving problems': {
      en: 'Struggles with reasoning or solving problems',
      ar: 'يواجه صعوبة في التفكير المنطقي أو تطبيق المعرفة في مواقف جديدة',
    },
    'Finds abstract concepts difficult': {
      en: 'Finds abstract concepts difficult',
      ar: 'يظهر تأخراً في فهم المفاهيم المجردة مقارنة بأقرانه',
    },
    'Has difficulty focusing attention, organizing tasks, or completing schoolwork': {
      en: 'Has difficulty focusing attention, organizing tasks, or completing schoolwork',
      ar: 'يواجه صعوبة في التركيز، تنظيم المهام، أو إكمال الأعمال المدرسية',
    },
    'Forgets instructions or details quickly': {
      en: 'Forgets instructions or details quickly',
      ar: 'يواجه صعوبة في الذاكرة أو الاحتفاظ بالمعلومات',
    },
    'Finds it challenging to solve problems independently, needing more assistance than peers': {
      en: 'Finds it challenging to solve problems independently, needing more assistance than peers',
      ar: 'يجد صعوبة في حل المشكلات بشكل مستقل، ويحتاج إلى مساعدة أكثر من أقرانه',
    },
    'Has difficulty understanding and following multi-step instructions, often getting confused or needing repeated guidance': {
      en: 'Has difficulty understanding and following multi-step instructions, often getting confused or needing repeated guidance',
      ar: 'يعاني من صعوبة في فهم واتباع التعليمات متعددة الخطوات، وغالباً ما يشعر بالارتباك أو يحتاج إلى تكرار التوجيهات',
    },
  },
  6: {
    'My child has trouble with talking or understanding language': {
      en: 'My child has trouble with talking or understanding language',
      ar: 'البيان الرئيسي: يعاني طفلي من مشاكل في نطق او فهم اللغة',
    },
    'Struggles with forming sentences or using grammar': {
      en: 'Struggles with forming sentences or using grammar',
      ar: 'يواجه صعوبة في تكوين الجمل أو استخدام القواعد النحوية',
    },
    'Mispronounces words or uses them incorrectly': {
      en: 'Mispronounces words or uses them incorrectly',
      ar: 'يخطئ في نطق الكلمات أو يستخدمها بشكل غير صحيح',
    },
    'Difficulty following directions or understanding questions': {
      en: 'Difficulty following directions or understanding questions',
      ar: 'يجد صعوبة في اتباع التعليمات أو فهم الأسئلة',
    },
    'Limited vocabulary affects ability to express thoughts': {
      en: 'Limited vocabulary affects ability to express thoughts',
      ar: 'لديه مفردات محدودة تؤثر على قدرته على التعبير',
    },
    'Finds it hard to engage in or follow conversations': {
      en: 'Finds it hard to engage in or follow conversations',
      ar: 'يجد صعوبة في المشاركة في المحادثات',
    },
    'Trouble with speech fluency, such as stuttering': {
      en: 'Trouble with speech fluency, such as stuttering',
      ar: 'يعاني من مشاكل في طلاقة الكلام مثل التلعثم',
    },
    'Difficulty with social aspects of communication, like using non-verbal cues (i.e. gestures, facial expressions, etc.)': {
      en: 'Difficulty with social aspects of communication, like using non-verbal cues (i.e. gestures, facial expressions, etc.)',
      ar: 'يواجه صعوبة في الجوانب الاجتماعية للتواصل، مثل استخدام الإشارات غير اللفظية',
    },
  },
  7: {
    'My child has trouble with eating, mealtime behavior, or food preferences': {
      en: 'My child has trouble with eating, mealtime behavior, or food preferences',
      ar: 'يعاني طفلي من مشاكل تتعلق بالأكل أو سلوكيات وقت الطعام أو تفضيلات الطعام',
    },
    'Has strong food aversions or a very limited diet, refusing certain textures or types of food': {
      en: 'Has strong food aversions or a very limited diet, refusing certain textures or types of food',
      ar: 'يظهر ميلًا قويًا للرفض الشديد لبعض الأطعمة أو لديه نظام غذائي محدود جدًا يرفض بعض الأنسجة أو أنواع الطعام',
    },
    'Has difficulty chewing or swallowing certain textures or foods': {
      en: 'Has difficulty chewing or swallowing certain textures or foods',
      ar: 'يواجه صعوبة في مضغ أو ابتلاع بعض الأنسجة أو الأطعمة',
    },
    'Displays disruptive behaviors during meals, such as tantrums or refusal to sit': {
      en: 'Displays disruptive behaviors during meals, such as tantrums or refusal to sit',
      ar: 'يظهر سلوكيات مشتتة أثناء الوجبات مثل الهستيريا أو رفض الجلوس',
    },
    'Eats at a significantly faster or slower pace than other children': {
      en: 'Eats at a significantly faster or slower pace than other children',
      ar: 'يتناول الطعام بوتيرة أسرع أو أبطأ بشكل ملحوظ مقارنةً مع الأطفال الآخرين',
    },
    'Demonstrates signs of being overly picky, limiting their diet variety': {
      en: 'Demonstrates signs of being overly picky, limiting their diet variety',
      ar: 'يظهر علامات الانتقائية الزائدة في اختيار الطعام، مما يؤدي إلى نطاق محدود من الأطعمة المقبولة وتقييد تنوع النظام الغذائي',
    },
    'Shows inconsistent appetite, alternating between poor eating and overeating': {
      en: 'Shows inconsistent appetite, alternating between poor eating and overeating',
      ar: 'يظهر شهية غير منتظمة، حيث يتناوب بين تناول الطعام بشكل غير كاف والإفراط في تناوله',
    },
  },
  8: {
    'My child struggles with sleep or has irregular sleep patterns': {
      en: 'My child struggles with sleep or has irregular sleep patterns',
      ar: 'طفلي يعاني من صعوبات في النوم أو نمط غير عادي للنوم',
    },
    'Takes a long time to fall asleep': {
      en: 'Takes a long time to fall asleep',
      ar: 'يستغرق وقتاً طويلاً للنوم',
    },
    'Wakes up frequently during the night': {
      en: 'Wakes up frequently during the night',
      ar: 'يستيقظ متكرراً خلال الليل',
    },
    'Has difficulty waking up in the morning and feels sleepy throughout the day': {
      en: 'Has difficulty waking up in the morning and feels sleepy throughout the day',
      ar: 'يواجه صعوبة في الاستيقاظ في الصباح وغالباً ما يشعر بالنعاس طوال اليوم',
    },
    'Resists bedtime, showing reluctance or refusing to go to bed': {
      en: 'Resists bedtime, showing reluctance or refusing to go to bed',
      ar: 'يعارض الذهاب إلى النوم ويظهر ممانعة أو رفضاً للذهاب إلى السرير',
    },
    'Experiences night terrors or nightmares, showing signs of fear or distress upon waking': {
      en: 'Experiences night terrors or nightmares, showing signs of fear or distress upon waking',
      ar: 'يعاني من رعب الليل أو كوابيس ويظهر علامات الخوف أو الاضطراب عند الاستيقاظ',
    },
    'Sleeps too little or too much compared to other children': {
      en: 'Sleeps too little or too much compared to other children',
      ar: 'ينام قليلاً أو كثيراً مقارنة مع الأطفال الآخرين',
    },
    'Displays unusual sleep behaviors like sleepwalking or talking during sleep': {
      en: 'Displays unusual sleep behaviors like sleepwalking or talking during sleep',
      ar: 'يظهر سلوكيات نوم غير عادية مثل المشي في النوم أو التحدث أثناء النوم',
    },
    'Has inconsistent sleep schedules, leading to daytime fatigue': {
      en: 'Has inconsistent sleep schedules, leading to daytime fatigue',
      ar: 'لديه جداول نوم غير منتظمة مما يؤدي إلى التعب أثناء النهار',
    },
  },
}

export const translateFamilyDynamics = {
  'Marital Status': {
    en: 'Marital Status',
    ar: 'الحالة الزوجية',
  },
  Married: {
    en: 'Married',
    ar: 'متزوج',
  },
  'Separated/Divorced': {
    en: 'Separated/Divorced',
    ar: 'منفصل/مطلق',
  },
  Other: {
    en: 'Other',
    ar: 'أخرى',
  },
  'Prefer not to answer': {
    en: 'Prefer not to answer',
    ar: 'يفضل عدم الإجابة',
  },
  'How often do you and your partner get along well?': {
    en: 'How often do you and your partner get along well?',
    ar: 'كم مرة تتفق مع شريكك بشكل جيد؟',
  },
  Often: {
    en: 'Often',
    ar: 'غالبًا',
  },
  Sometimes: {
    en: 'Sometimes',
    ar: 'أحيانًا',
  },
  Rarely: {
    en: 'Rarely',
    ar: 'نادراً',
  },
  'Not applicable': {
    en: 'Not applicable',
    ar: 'غير مطبق',
  },
  'How often do you and your partner discuss parenting decisions?': {
    en: 'How often do you and your partner discuss parenting decisions?',
    ar: 'كم مرة تناقش أنت وشريكك قرارات التربية؟',
  },
  "How often do you and your child's other parent collaborate on parenting decisions?": {
    en: "How often do you and your child's other parent collaborate on parenting decisions?",
    ar: 'كم مرة تتعاون مع والد الطفل الآخر في قرارات التربية؟',
  },
  "How much do your child's needs or behaviors affect your daily family life?": {
    en: "How much do your child's needs or behaviors affect your daily family life?",
    ar: 'إلى أي مدى تؤثر احتياجات طفلك أو سلوكياته على حياتك العائلية اليومية؟',
  },
  'A lot': {
    en: 'A lot',
    ar: 'كثيراً',
  },
  'A little': {
    en: 'A little',
    ar: 'قليلاً',
  },
  'Not at all': {
    en: 'Not at all',
    ar: 'مطلقاً',
  },
  "How much do your child's needs or behaviors limit your family's participation in everyday activities?": {
    en: "How much do your child's needs or behaviors limit your family's participation in everyday activities?",
    ar: 'إلى أي مدى تؤثر احتياجات طفلك أو سلوكياته على مشاركتك العائلية في الأنشطة اليومية؟',
  },
}

export const translateParentalWellbeing = {
  Often: {
    en: 'Often',
    ar: 'غالبًا',
  },
  Sometimes: {
    en: 'Sometimes',
    ar: 'أحيانًا',
  },
  'Not at all': {
    en: 'Not at all',
    ar: 'لا شيء',
  },
  'Prefer not to answer': {
    en: 'Prefer not to answer',
    ar: 'يفضل عدم الإجابة',
  },
  'A little': {
    en: 'A little',
    ar: 'قليلاً',
  },
  'A lot': {
    en: 'A lot',
    ar: 'كثيراً',
  },
  'Feeling stressed or overwhelmed': {
    en: 'Feeling stressed or overwhelmed',
    ar: 'الشعور بالتوتر أو الإرهاق',
  },
  'Over the past two weeks, how often have you experienced the following?': {
    en: 'Over the past two weeks, how often have you experienced the following?',
    ar: 'خلال الأسبوعين الماضيين، كم مرة شعرت بالأعراض التالية؟',
  },
  'Feeling anxious or worried': {
    en: 'Feeling anxious or worried',
    ar: 'الشعور بالقلق أو التوتر',
  },
  'Low mood or sad': {
    en: 'Low mood or sad',
    ar: 'المزاج السيء أو الحزن',
  },
  "How much do your child's needs or behaviors impact your mental wellbeing?": {
    en: "How much do your child's needs or behaviors impact your mental wellbeing?",
    ar: 'إلى أي مدى تؤثر احتياجات طفلك أو سلوكياته على رفاهيتك النفسية؟',
  },
  "How much do your child's needs or behaviors impact your relationship with your partner?": {
    en: "How much do your child's needs or behaviors impact your relationship with your partner?",
    ar: 'إلى أي مدى تؤثر احتياجات طفلك أو سلوكياته على علاقتك بشريكك؟',
  },
}

export const categoryLocals = {
  'Emotional Symptoms': {
    en: 'Emotional Symptoms',
    ar: 'أعراض عاطفية',
  },
  'Conduct Problems': {
    en: 'Conduct Problems',
    ar: 'مشاكل سلوكية',
  },
  'Hyperactivity/Inattention': {
    en: 'Hyperactivity/Inattention',
    ar: 'التوتر/الانتباه',
  },
  'Combined Peer and Social Interactions': {
    en: 'Combined Peer and Social Interactions',
    ar: 'التفاعلات الاجتماعية والأصدقاء',
  },
  'Motor Skills Development': {
    en: 'Motor Skills Development',
    ar: 'تطور المهارات الحركية',
  },
  'Expanded Cognitive Development': {
    en: 'Expanded Cognitive Development',
    ar: 'تطور الذكاء الموسع',
  },
  'Speech and Language Development': {
    en: 'Speech and Language Development',
    ar: 'تطور اللغة والكلام',
  },
  ' Eating-related Issues': {
    en: ' Eating-related Issues',
    ar: ' مشاكل الأكل',
  },
  'Sleep-Related Issues': {
    en: ' Sleep-Related Issues',
    ar: ' مشاكل النوم',
  },
  'Family dynamics': {
    en: 'Family dynamics',
    ar: 'العلاقات الأسرية',
  },
  'Parental Wellbeing': {
    en: 'Parental Wellbeing',
    ar: 'رفاهية الأبوين',
  },
}

export const commonLocals = {
  en: {
    Questionnaire: 'Questionnaire',
    Additional: 'Additional information',
    Yes: 'Yes',
    No: 'No',
    Skipped: 'Skipped',
    Often: 'Often',
    'Prefer not to answer': 'Prefer not to answer',
    Sometimes: 'Sometimes',
    'Not at all': 'Not at all',
    'A little': 'A little',
    'A lot': 'A lot',
    Married: 'Married',
    'Separated/Divorced': 'Separated/Divorced',
    Other: 'Other',
    'Prefer not to answer': 'Prefer not to answer',
    Rarely: 'Rarely',
    'Not applicable': 'Not applicable',
    'Advisor comment': 'Advisor comment',
    Report: 'Report',
    'Now viewing in parents preferred language': 'Now viewing in parents preferred language',
    Skipped: 'Skipped',
  },
  ar: {
    Questionnaire: 'استبيان',
    Additional: 'معلومات إضافية',
    Yes: 'نعم',
    No: 'لا',
    Skipped: 'تم تخطيه',
    Offen: 'غالبًا',
    'Prefer not to answer': 'يفضل عدم الإجابة',
    Sometimes: 'أحيانًا',
    'Not at all': 'مطلقاً',
    'A little': 'قليلاً',
    'A lot': 'كثيراً',
    Married: 'متزوج',
    'Separated/Divorced': 'منفصل/مطلق',
    Other: 'أخرى',
    'Prefer not to answer': 'يفضل عدم الإجابة',
    Rarely: 'نادراً',
    'Not applicable': 'غير مطبق',
    'Advisor comment': 'تعليق المستشار',
    Report: 'تقرير',
    'Now viewing in parents preferred language': 'يتم العرض الآن باللغة المفضلة للوالدين',
    Skipped: 'تم التخطي',
  },
}
