// Core
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
// Styles
import Styles from './Styles.module.scss'
// Icons
import { ReactComponent as SuccessIcon } from '../../../../theme/assets/images/onboarding-parent-success.svg'
// i18n
import { useTranslation } from 'react-i18next'
import i18n from '../../../../i18n'
import en from './locale/en.json'
import ar from './locale/ar.json'

export const TherapistSuccessAccountCreate = () => {
  const [searchParams] = useSearchParams()

  const [selectedLanguage, setSelectedLanguage] = useState(searchParams.get('lng'))

  const { t } = useTranslation()

  useEffect(() => {
    setSelectedLanguage(searchParams.get('lng'))
    i18n.changeLanguage(searchParams.get('lng') ?? 'en')
  }, [searchParams])
  useEffect(() => {
    i18n.addResourceBundle('en', 'success', en)
    i18n.addResourceBundle('ar', 'success', ar)
  }, [])

  return (
    <div className={Styles.main_container}>
      <SuccessIcon />
      <h4>{t('success:header')}</h4>
      <div className={Styles.main_container__text}>{t('success:content:description')}</div>
      <p>{t('success:content:subtitle')}</p>
    </div>
  )
}
