import { useEffect, useState } from 'react'

export const useGetLanguage = (userPreferredLanguage, userType) => {
  const [language, setLanguage] = useState('')
  const localStorageLanguage = localStorage.getItem('language')
  useEffect(() => {
    console.log(userType, userPreferredLanguage)
    if (localStorageLanguage && userType === 'parent') {
      setLanguage(localStorageLanguage)
    } else {
      setLanguage(userPreferredLanguage ?? 'en')
    }
  }, [localStorageLanguage, userPreferredLanguage, userType])
  return language
}
