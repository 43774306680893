// Core
import { useEffect, useState } from 'react'
//Styles
import Styles from './Styles.module.scss'
//MUI
import styled from '@emotion/styled'
import { Modal, Step, StepContent, StepLabel, Stepper } from '@mui/material'
//Icons
import { ReactComponent as ArrowDownIcon } from '../../../theme/assets/icons/arrow_down_icon.svg'
import { ReactComponent as ArrowUpIcon } from '../../../theme/assets/icons/arrow_up_icon.svg'
import { ReactComponent as CloseIcon } from '../../../theme/assets/icons/close_updated.svg'
// i18n
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import ar from './locale/ar.json'
import en from './locale/en.json'
// Components
import { CheckboxField } from '../Fields'
//Form
import { useForm } from 'react-hook-form'

export const StepperModal = ({ open, onClose, data }) => {
  const { t } = useTranslation()

  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language'))

  const [isOpenDetails, setIsOpenDetails] = useState(false)
  const [activeStep, setActiveStep] = useState(null)

  useEffect(() => {
    setSelectedLanguage(localStorage.getItem('language'))
  }, [localStorage.getItem('language')])

  useEffect(() => {
    i18n.addResourceBundle('en', 'hint_modal', en)
    i18n.addResourceBundle('ar', 'hint_modal', ar)
  }, [])

  //Styles
  const CustomStep = styled(Step)`
    & .MuiSvgIcon-root {
      color: #c7bcce;
      margin-left: ${selectedLanguage === 'ar' && '8px'};
    }
    & .MuiStepIcon-text {
      fill: #FFFFFF;
    }
    ,
    & .MuiStepIcon-root.Mui-active {
      color: #8450a0 !important;
      font-family: 'Poppins_Regular';
      font-weight: 400;
    }
    ,
    & .MuiStepLabel-label.Mui-disabled {
      font-family: 'Poppins_Regular';
    }
    ,
    & .MuiStepLabel-label {
      font-size: 16px !important;
      font-family: 'Poppins_Regular';
      font-weight: 600;
      color: #8450a0 !important;
      cursor: pointer;
    }
    ,
    & .MuiStepIcon-root.Mui-completed {
      color: #c7bcce !important;
    }
    & .MuiCollapse-wrapperInner {
      color: #9b9b9b !important;
      font-size: 14px !important;
      margin-left: 5px;
      font-family: 'Poppins_Regular';
    },
    & .MuiStepContent-root {
      border: none;
    },
  `

  const { handleSubmit, setValue, control } = useForm({
    mode: 'all',
    defaultValues: {
      is_dont_show: false,
    },
  })

  const handleCloseModal = (formData) => {
    if (formData?.is_dont_show) {
      data?.action()
    } else {
      onClose()
    }
  }

  const handleOpenDetails = (stepId) => {
    if (activeStep === stepId && isOpenDetails) {
      setIsOpenDetails(false)
    } else {
      setIsOpenDetails(true)
    }

    setActiveStep(stepId)
  }

  return (
    <Modal open={open} onClose={onClose}>
      <div className={Styles.main_container}>
        <div className={Styles.header}>
          <CloseIcon className={Styles.header__icon} onClick={onClose} />
        </div>
        <div style={{ direction: selectedLanguage === 'ar' && 'rtl' }} className={Styles.stepper}>
          <Stepper connector={null} orientation="vertical">
            {data?.data?.map((step) => (
              <CustomStep style={{ marginBottom: '16px' }} active={true} key={step?.id}>
                <div onClick={() => handleOpenDetails(step?.id)} className={Styles.stepper__label}>
                  <StepLabel>{step?.label}</StepLabel>
                  {isOpenDetails && step?.id === activeStep ? <ArrowUpIcon /> : <ArrowDownIcon />}
                </div>
                {isOpenDetails && step?.id === activeStep && <StepContent>{step?.description}</StepContent>}
              </CustomStep>
            ))}
          </Stepper>
        </div>
        <div style={{ justifyContent: data?.isFirstView ? 'space-between' : 'flex-end', direction: selectedLanguage === 'ar' && 'rtl' }} className={Styles.footer}>
          {data?.isFirstView && (
            <div>
              <CheckboxField
                class={Styles.check_box}
                control={control}
                name={'is_dont_show'}
                onCustomChange={(value) => setValue('is_dont_show', value)}
                children={<div className={Styles.check_box__content}>{t('hint_modal:dont_show')}</div>}
              />
            </div>
          )}
          <div onClick={handleSubmit(handleCloseModal)} className={Styles.btn}>
            {t('hint_modal:close')}
          </div>
        </div>
      </div>
    </Modal>
  )
}
