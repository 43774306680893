import { useEffect, useMemo, useState } from 'react'
import { parent_onboarding_report_family_dynamics, parent_onboarding_report_parental_wellbeing } from '../../data'
import { returnParagraphForNoReport } from '../Auth/ParentOnboarding/ParentReport/locale/locale.functions'
import { MAIN_STATEMENTS_ANSWERS } from '../../data/enums'

export const useQuestionnaireReportGenerator = ({ questionnaire, familyDynamics, childName, parentalWellbeing, language }) => {
  /**@LocalStates */
  const [familyDynamicsReport, setFamilyDynamicsReport] = useState({})
  const [parentalWellbeingReport, setParentalWellbeingReport] = useState({})
  const [isReportExist, setIsReportExist] = useState(true)

  /**@Effects */
  useEffect(() => {
    if (familyDynamics) {
      setFamilyDynamicsReport(parent_onboarding_report_family_dynamics(childName, familyDynamics))
    }
    if (parentalWellbeing) {
      setParentalWellbeingReport(parent_onboarding_report_parental_wellbeing(childName, parentalWellbeing))
    }
  }, [familyDynamics, childName, parentalWellbeing])
  useEffect(() => {
    const sectionWithYesAnswer = questionnaire?.find((section) => section?.main_statement_answer === MAIN_STATEMENTS_ANSWERS?.[0])
    if (!sectionWithYesAnswer && !parentalWellbeingReport && !familyDynamicsReport) {
      setIsReportExist(false)
    } else {
      setIsReportExist(true)
    }
  }, [familyDynamicsReport, parentalWellbeingReport, questionnaire])

  const defaultParagraphForReport = useMemo(() => returnParagraphForNoReport(childName)?.[language], [language, childName])

  return { isReportExist, defaultParagraphForReport, parentalWellbeingReport, familyDynamicsReport }
}
