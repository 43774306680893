//Core
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useMemo, useState } from 'react'
//MUI
import { Modal } from '@mui/material'
//Styles
import Styles from './Styles.module.scss'
//Icons
import { ReactComponent as CloseIcon } from '../../../../theme/assets/icons/close_updated.svg'
import { ReactComponent as EditIcon } from '../../../../theme/assets/icons/edit_icon.svg'
import { ReactComponent as SaveIcon } from '../../../../theme/assets/icons/cloud-save-icon.svg'
import { ReactComponent as ErrorIcon } from '../../../../theme/assets/icons/error.svg'
//Helpers
import { convertDate } from '../../../../utils'
//Actions
import { zoomActions } from '../../../../actions'
//Form
import { useForm } from 'react-hook-form'
//Components
import { TextAreaField } from '../../../GeneralComponents'
import { Spinner } from '../../../ChatSystem/Common/Spinner'
//Data
import { SESSION_NOTE_STATUS } from '../../../../data'
//Utils
import { useDebouncedCallback } from 'use-debounce'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import i18n from '../../../../i18n'
import en from './locales/en.json'
import ar from './locales/ar.json'

/**
 * SessionNoteModal component for displaying and managing session notes.
 * @param {object} props - Component props.
 * @param {boolean} props.open - Whether the modal is open.
 * @param {function} props.onClose - Callback function for closing the modal.
 * @param {object} props.record - Session record data.
 * @param {string} props.clientName - Name of the client.
 * @param {object} props.parent - Parent data.
 * @returns {JSX.Element} SessionNoteModal component.
 */
export const SessionNoteModal = ({ open, onClose, record, clientName, parent }) => {
  const dispatch = useDispatch()

  const [selectedTab, setSelectedTab] = useState('main')
  const [noteStatus, setNoteStatus] = useState(SESSION_NOTE_STATUS[2])
  const [isDraftExist, setIsDraftExist] = useState(false)

  const user = useSelector((state) => state.auth.user)
  const loading = useSelector((state) => state.general.modalLoading)

  const {
    formState: { errors },
    handleSubmit,
    setValue,
    control,
    getValues,
  } = useForm({
    mode: 'all',
    defaultValues: {
      subjective: '',
      objective: '',
      assessment: '',
      plan: '',
    },
  })

  /**Initialize translation */
  const { t } = useTranslation()

  useEffect(() => {
    i18n.changeLanguage(user?.user?.preferred_language)
  }, [user?.user?.preferred_language])

  useEffect(() => {
    i18n.addResourceBundle('en', 'sessionModal', en)
    i18n.addResourceBundle('ar', 'sessionModal', ar)
  }, [])

  /**
   * Effect to initialize form values and manage draft existence based on record changes.
   */
  useEffect(() => {
    if (record?.meeting_notes?.subjective) {
      setValue('assessment', record?.meeting_notes?.assessment)
      setValue('objective', record?.meeting_notes?.objective)
      setValue('plan', record?.meeting_notes?.plan)
      setValue('subjective', record?.meeting_notes?.subjective)
    }

    if (record?.meeting_notes?.draft_exists) {
      setIsDraftExist(true)
      setSelectedTab('draft')
    } else {
      setIsDraftExist(false)
      setSelectedTab('main')
    }
  }, [record])

  /**
   * Handles approving session notes.
   */
  const handleApproveNote = () => {
    const dataForRequest = {
      meetingId: record?.calcom_session?.zoom_meeting_id,
      token: user?.token,
      payload: {
        approved: true,
        meeting_notes_id: record?.meeting_notes?.id,
      },
    }

    dispatch(zoomActions.approveSessionNote(dataForRequest))
  }

  /**
   * Handles switching to draft mode for editing.
   */
  const handleEditNote = () => {
    setIsDraftExist(true)
    setSelectedTab('draft')
  }

  /**
   * Handles updating session note data.
   * @param {object} data - Updated session note data.
   */
  const handleUpdateNote = (data) => {
    const dataForRequest = {
      meetingId: record?.calcom_session?.zoom_meeting_id,
      payload: {
        ...data,
        parent_user_id: parent?.id,
        meeting_notes_id: record?.meeting_notes?.id,
      },
      token: user?.token,
      action: () => {
        const dataForRequest = {
          meetingId: record?.calcom_session?.zoom_meeting_id,
          payload: {
            meeting_notes_id: record?.meeting_notes?.id,
          },
          token: user?.token,
        }
        dispatch(zoomActions.submitSessionNote(dataForRequest))
      },
    }

    dispatch(zoomActions.updateSessionNote(dataForRequest))
  }

  /**
   * Debounced callback for auto-saving session note changes
   */
  const handleAutoSave = useDebouncedCallback(() => {
    const data = getValues()
    const dataForRequest = {
      meetingId: record?.calcom_session?.zoom_meeting_id,
      payload: {
        ...data,
        parent_user_id: parent?.id,
        meeting_notes_id: record?.meeting_notes?.id,
      },
      token: user?.token,
      action: () => setNoteStatus(SESSION_NOTE_STATUS[2]),
      actionOnError: () => {
        setNoteStatus(SESSION_NOTE_STATUS[0])
        setValue('assessment', record?.meeting_notes?.assessment || '')
        setValue('objective', record?.meeting_notes?.objective || '')
        setValue('plan', record?.meeting_notes?.plan || '')
        setValue('subjective', record?.meeting_notes?.subjective || '')
        setTimeout(() => {
          setNoteStatus(SESSION_NOTE_STATUS[2])
        }, 2000)
      },
    }

    dispatch(zoomActions.updateSessionNote(dataForRequest))
  }, 1000)

  /**
   * Handles selecting a tab (main or draft).
   * @param {string} option - Selected tab option ('main' or 'draft').
   */
  const handleSelectTab = (option) => {
    setSelectedTab(option)
  }

  /**
   * Returns styles based on session note status.
   * @returns {object} Styles for session note status.
   */
  const returnSavingData = () => {
    const dataStatus = {}

    switch (noteStatus) {
      case SESSION_NOTE_STATUS[0]:
        dataStatus.background = '#f1c4b3'
        dataStatus.color = '#d75221'
        break
      case SESSION_NOTE_STATUS[1]:
        dataStatus.background = '#feebdc'
        dataStatus.color = '#fe7000'
        break
      case SESSION_NOTE_STATUS[2]:
        dataStatus.background = '#e0f9f5'
        dataStatus.color = '#1cd4b0'
        break
    }

    return dataStatus
  }

  /**
   * Handles discarding unsaved changes.
   */
  const handleDiscardChanges = () => {
    const dataForRequest = {
      meetingId: record?.calcom_session?.zoom_meeting_id,
      payload: {
        meeting_notes_id: record?.meeting_notes?.id,
      },
      token: user?.token,
    }

    if (confirm('Are you sure you want to discard your changes? The notes will revert to the last submitted')) dispatch(zoomActions.discardEdditingSessionNote(dataForRequest))
  }
  const direction = useMemo(() => {
    if (user?.user?.preferred_language === 'ar') {
      return 'rtl'
    }
    return 'ltr'
  }, [user?.user?.preferred_language])

  return (
    <Modal id={'session_note'} BackdropProps={{ onClick: () => {} }} open={open} onClose={onClose}>
      <div style={{ direction }} className={Styles.main_container}>
        {loading && (
          <div className={Styles.loading}>
            <Spinner />
          </div>
        )}
        <div className={Styles.header}>
          <div>
            <h4>{t('sessionModal:sessionNote')}</h4>
            {!record?.meeting_notes?.approved_at && user?.user?.user_type === 'therapist' && (
              <div style={{ background: returnSavingData().background, color: returnSavingData().color }} className={Styles.save}>
                {noteStatus === SESSION_NOTE_STATUS[0] && <ErrorIcon className={Styles.save__error} />}
                {noteStatus === SESSION_NOTE_STATUS[1] && <Spinner width="20px" background={returnSavingData().background} color={returnSavingData().color} />}
                {noteStatus === SESSION_NOTE_STATUS[2] && <SaveIcon style={{ fill: returnSavingData().color }} />}
                <p>
                  {noteStatus} {noteStatus === SESSION_NOTE_STATUS[2] && dayjs(record?.meeting_notes?.updated_at).format('MMM DD, h:mm A')}
                </p>
              </div>
            )}
          </div>
          <CloseIcon className={Styles.header__icon} onClick={onClose} />
        </div>
        <div className={Styles.main_container__content}>
          <div className={Styles.main_container__content__info_block}>
            <div className={Styles.main_container__content__info_block__session_details}>
              <p>{user?.user?.user_type === 'therapist' ? 'Client name' : t('sessionModal:therapistName')}: </p>
              <span>{record?.therapist?.full_name || clientName}</span>
            </div>
            <div className={Styles.main_container__content__info_block__session_details}>
              <p>Date: </p>
              <span>{convertDate(record?.calcom_session?.start_time)}</span>
            </div>
          </div>
          {isDraftExist && (
            <div className={Styles.versions_container}>
              <div onClick={() => handleSelectTab('main')} className={selectedTab === 'main' ? Styles.versions_container__selected_tab : Styles.versions_container__tab}>
                Submitted
              </div>
              <div onClick={() => handleSelectTab('draft')} className={selectedTab === 'draft' ? Styles.versions_container__selected_tab : Styles.versions_container__tab}>
                Draft
              </div>
            </div>
          )}
          {selectedTab === 'main' && (
            <div className={Styles.main_container__content}>
              <div className={Styles.main_container__content__info_block}>
                <h6 style={{ fontSize: '18px' }}>{t('sessionModal:subjective')}</h6>
                <p>{record?.meeting_notes?.submitted_subjective || 'Skipped'}</p>
              </div>
              <div className={Styles.main_container__content__info_block}>
                <h6 style={{ fontSize: '18px' }}>{t('sessionModal:objective')}</h6>
                <p>{record?.meeting_notes?.submitted_objective || 'Skipped'}</p>
              </div>
              <div className={Styles.main_container__content__info_block}>
                <h6 style={{ fontSize: '18px' }}>{t('sessionModal:assessment')}</h6>
                <p>{record?.meeting_notes?.submitted_assessment || 'Skipped'}</p>
              </div>
              <div className={Styles.main_container__content__info_block}>
                <h6 style={{ fontSize: '18px' }}>Plan</h6>
                <p>{record?.meeting_notes?.submitted_plan || 'Skipped'}</p>
              </div>
              {!record?.meeting_notes?.approved_at && user?.user?.user_type === 'parent' ? (
                <div onClick={handleApproveNote} className={Styles.main_container__content__btn}>
                  {t('sessionModal:approve')}
                </div>
              ) : record?.meeting_notes?.approved_at ? (
                <div className={Styles?.approved_data}>
                  <span>Approved on: </span>
                  <p>{convertDate(record?.meeting_notes?.approved_at)}</p>
                </div>
              ) : (
                <div className={Styles?.approved_data}>
                  <span style={{ fontSize: '14px', color: '#C7BCCE' }}>{clientName} hasn't approved yet.</span>
                </div>
              )}
            </div>
          )}
          {selectedTab === 'main' && user?.user?.user_type === 'therapist' && !record?.meeting_notes?.approved_at && (
            <div onClick={handleEditNote} className={Styles.edit}>
              <EditIcon />
              <p>Edit</p>
            </div>
          )}
          {selectedTab === 'draft' && (
            <div className={Styles.main_container__content__therapist_block}>
              <TextAreaField
                name="subjective"
                control={control}
                onChange={(event) => {
                  setValue('subjective', event.target.value, {
                    shouldValidate: true,
                  })
                  setLength(event.target.value.length)
                }}
                label={'Subjective'}
                class={Styles.textarea_wrap}
                classError={Styles.error}
                placeholder={'Subjective'}
                error={errors?.subjective}
                customOnChange={() => {
                  setNoteStatus(SESSION_NOTE_STATUS[1])
                  handleAutoSave()
                }}
              />
              <TextAreaField
                name="objective"
                control={control}
                onChange={(event) => {
                  setValue('objective', event.target.value, {
                    shouldValidate: true,
                  })
                  setLength(event.target.value.length)
                }}
                label={'Objective'}
                class={Styles.textarea_wrap}
                classError={Styles.error}
                placeholder={'Objective'}
                error={errors?.objective}
                customOnChange={() => {
                  setNoteStatus(SESSION_NOTE_STATUS[1])
                  handleAutoSave()
                }}
              />
              <TextAreaField
                name="assessment"
                control={control}
                onChange={(event) => {
                  setValue('assessment', event.target.value, {
                    shouldValidate: true,
                  })
                  setLength(event.target.value.length)
                }}
                label={'Assessment'}
                class={Styles.textarea_wrap}
                classError={Styles.error}
                placeholder={'Assessment'}
                error={errors?.assessment}
                customOnChange={() => {
                  setNoteStatus(SESSION_NOTE_STATUS[1])
                  handleAutoSave()
                }}
              />
              <TextAreaField
                name="plan"
                control={control}
                onChange={(event) => {
                  setValue('plan', event.target.value, {
                    shouldValidate: true,
                  })
                  setLength(event.target.value.length)
                }}
                label={'Plan'}
                class={Styles.textarea_wrap}
                classError={Styles.error}
                placeholder={'Plan'}
                error={errors?.plan}
                customOnChange={() => {
                  setNoteStatus(SESSION_NOTE_STATUS[1])
                  handleAutoSave()
                }}
              />
            </div>
          )}
          {selectedTab === 'draft' && (
            <div className={Styles.buttons} style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div onClick={handleDiscardChanges} className={Styles.main_container__content__btn_gray}>
                Discard changes
              </div>
              <div onClick={handleSubmit(handleUpdateNote)} className={Styles.main_container__content__btn}>
                Update and send for approval
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}
